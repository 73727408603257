import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "app/axios";
import { RootState } from "app/store";
import { Project, NewsItem } from "types/interfaces";


interface initialState {
  data: NewsItem[];
}


const initialState: initialState = {
  data: []
};

export function getRawDateStringNews(feed: NewsItem, language: string): string {
  if(language.includes("de")) {
    return new Date(feed.created_at).toLocaleDateString("de", {year: "numeric", day: "numeric",  month: "short"});
  } else {
    return new Date(feed.created_at).toLocaleDateString("en", {year: "numeric", day: "numeric",  month: "short"}); // "en-GB"
  }
}

export const fetchNewsfeed = createAsyncThunk(
  "newsfeed/fetchNewsfeed",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const response = await axiosInstance.get("newsfeed");
    console.log(response)
    if (response.status === 200) {
      const { data } = response;
      return data
    } else {
      return rejectWithValue(response.data.status);
    }
  }
);

const newsfeedSlice = createSlice({
  name: "newsfeed",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchNewsfeed.fulfilled,
        (
          state,
          action: PayloadAction<{ data: any }>
        ) => {
         state.data = action.payload.data || [];
        }
      )
  },
});


export const selectNewsfeed = (state: RootState) => state.newsfeed.data || [];
export default newsfeedSlice.reducer;

