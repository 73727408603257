import { Close, PinDrop } from "@mui/icons-material";
import { Box, Container, FormControl, FormControlLabel, IconButton, InputAdornment, Link, Pagination, Radio, RadioGroup, SvgIcon, TextField } from "@mui/material";
import { HDButton } from "components/buttons/HDButton";
import { HeaderText, InfoText } from "components/typography";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import backgroundImage from "assets/img/Heidelberg_Materials_Header_Projektliste.jpg"
import H5 from "components/typography/H5";
import LabelText from "components/typography/LabelText";
import InfoTextExa from "components/typography/InfoTextExa";
import RichText from "components/RichText";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { cancelParticipation, fetchProjects, getDescriptionString, getNameString, getStateString, isRunning, participate, selectProjects, setProject, setVolunteerModal } from "features/projects/projectsSlice";
import { Project } from "types/interfaces";
import H4 from "components/typography/H4";
import { selectParticipant, setPermissionMissing } from "features/auth/authSlice";
import { Volunteer } from "components/modals/Volunteer";
import { ReturnUrlContext } from "components/ReturnUrlContext";
import { RootState } from "app/store";

var showSearchTimeout: any

export function Projectlist() {
  const [ongoingProjects, setOngoingProjects] = useState<Project[]>()
  const [finishedProjects, setFinishedProjects] = useState<Project[]>()
  const [filteredProjects, setFilteredProjects] = useState<Project[]>()
  const [filterActive, setFilterActive] = useState(false)
  const { setReturnUrl } = useContext(ReturnUrlContext);
  const [projects, setProjects] = useState<Project[]>(useAppSelector(selectProjects))
  const [updatedProject, setUpdatedProject] = useState<Project>()
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("")
  const [searchType, setSearchType] = useState(0)
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const participant = useAppSelector(selectParticipant)

  const [ongoingPage, setOngoingPage] = useState(1);
  const [finishedPage, setFinishedPage] = useState(1);
  const [filteredPage, setFilteredPage] = useState(1);
  const [displayedOngoingProjects, setDisplayedOngoingProjects] = useState<Project[]>()
  const [displayedFinishedProjects, setDisplayedFinishedProjects] = useState<Project[]>()
  const [displayedFilteredProjects, setDisplayedFilteredProjects] = useState<Project[]>()

  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const itemsPerPage = 6;

  const handleImageClick = (project: Project) => {
    dispatch(setProject(project))
    navigate("/project_details")
  }
  const getDisplayedProjects = (
    projects: Project[],
    currentPage: number
  ) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return projects.slice(startIndex, endIndex);
  };
  // the pagination page change works, however the displayed only change after changing the page twice
  const filterProjects = () => {
    if (projects) {
      const ongoing = projects.filter((project: Project) => project.status === 2);
      const finished = projects.filter((project: Project) => project.status === 3);
      setOngoingProjects([...ongoing])
      setFinishedProjects([...finished])
      const ongoingBuffer = getDisplayedProjects(
        ongoing,
        ongoingPage
      );
      const finishedBuffer = getDisplayedProjects(
        finished,
        finishedPage
      );
      setDisplayedOngoingProjects([...ongoingBuffer]);
      setDisplayedFinishedProjects([...finishedBuffer]);
    }
  }

  useEffect(() => {
    if(showSearchTimeout) clearTimeout(showSearchTimeout);
    
    if (searchQuery || searchType) {
      showSearchTimeout = setTimeout(()=>{
        let search = searchQuery.toLowerCase();
        const filtered = projects.filter((project: Project) => {
          if (i18n.language.includes("de")) {
            if (searchType === 0) {
              return project.name ? project.name.toLowerCase().includes(search) : false
                || project.teaser ? project.teaser.toLowerCase().includes(search) : false
                  || project.description ? project.description.toLowerCase().includes(search) : false
  
            } else {
              return project.status === searchType
                && (project.name ? project.name.toLowerCase().includes(search) : false
                  || project.teaser ? project.teaser.toLowerCase().includes(search) : false
                    || project.description ? project.description.toLowerCase().includes(search) : false
                )
            }
          } else {
            if (searchType === 0) {
              return project.name ? project.name.toLowerCase().includes(search) : false
                || project.teaser_en ? project.teaser_en.toLowerCase().includes(search) : false
                  || project.description_en ? project.description_en.toLowerCase().includes(search) : false
  
            } else {
              return project.status === searchType
                && (project.name ? project.name.toLowerCase().includes(search) : false
                  || project.teaser_en ? project.teaser_en.toLowerCase().includes(search) : false
                    || project.description_en ? project.description_en.toLowerCase().includes(search) : false
                )
            }
          }
        })

        setFilterActive(true);
        setFilteredProjects([...filtered])
        const filteredBuffer = getDisplayedProjects(
          filtered,
          filteredPage
        );
        setDisplayedFilteredProjects([...filteredBuffer])

        //filtered projects are correct, but are not yet displayed correctly.
        filterProjects();
      }, 400)
    } else {
      setFilterActive(false);
      filterProjects();
    }

  }, [projects, searchQuery, searchType])


  const handleOngoingPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setOngoingPage(page);
    if (ongoingProjects) {
      const ongoingBuffer = getDisplayedProjects(
        ongoingProjects,
        page
      );
      setDisplayedOngoingProjects([...ongoingBuffer]);
    }
  };


  const handleFinishedPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setFinishedPage(page);
    if (finishedProjects) {
      const finishedBuffer = getDisplayedProjects(
        finishedProjects,
        page
      );
      setDisplayedFinishedProjects([...finishedBuffer]);
    };
  };


  const handleFilteredPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setFilteredPage(page);
    if (filteredProjects) {
      const filteredBuffer = getDisplayedProjects(
        filteredProjects,
        page
      );
      setDisplayedFilteredProjects([...filteredBuffer]);
    }
  };



  const handleVolunteerButton = (type: number, id?: number) => {
    let selectedProject: Project | undefined;
    if (id) selectedProject = projects.filter((project) => project.id === id)[0]
    switch (type) {
      case 0:
        if (id) {
          dispatch(participate({projectId: id, language: i18n.language})).then((res: any) => {
            if (res.payload.status === "ok") {
              if (selectedProject && participant) {
                setUpdatedProject({ ...selectedProject, attending_participants_ids: [...selectedProject.attending_participants_ids, participant.id] })
                if (updatedProject) setProjects([...projects, updatedProject])
                dispatch(fetchProjects()).then((res) => {
                  dispatch(setVolunteerModal(true))
                })
              }
            }
          })
        }
        break;
      case 1:
        setReturnUrl("/project_details");
        navigate("/login")
        dispatch(setPermissionMissing(true))
        break;
      case 2:
        if (id) {
          dispatch(cancelParticipation(id)).then((res: any) => {
            if (res.payload.status === "ok") {
              if (selectedProject && participant) {
                setUpdatedProject({ ...selectedProject, attending_participants_ids: selectedProject?.attending_participants_ids.filter((id) => id !== participant.id) })
                if (updatedProject) setProjects([...projects, updatedProject])
                dispatch(fetchProjects()).then((res) => {
                  navigate(0)
                })
              }
            }
          })
        }
        break;
      default:
        break
    }
  }

  const handleSubmitProjectButton = () => {
    if (isAuthenticated) {
      navigate("/create_project");
    } else {
      setReturnUrl("/create_project");
      navigate("/login")
      dispatch(setPermissionMissing(true))
    }
  }

  // const handleSearchType = (val: string) => {
  //   setFilterActive(false)
  //   setSearchType(parseInt(val))
  // }

  // const showFilteredProjects = () => {
  //   setFilterActive(true)
  // }

  var CardGapPx = 62;
  return (
    <Box>
      <Box sx={{
        marginBottom: "20px",
      }}>
        <Box sx={{
          position: "relative",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          minHeight: { xs: "auto", md: "400px" },
          gap: 4,
        }}>
          <Box
            component={"img"}
            src={backgroundImage}
            sx={{
              right: 0,
              position: { xs: "relative", md: "absolute" },
              width: { xs: "100%", md: "50%" },
              height: "100%",
              borderRadius: { xs: "none", md: "0 0 0 140px" },
              objectFit: "cover",
            }}
          />
          <Container maxWidth="xl" sx={{
            display: "flex",
            width: "100%",
            // justifyContent: "center",
          }}>
            <Box sx={{
              // p: {xs: "20px 0 0 50px"},
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: { xs: "100%", md: "50%" },
            }}>
              <Box sx={{
                paddingRight: { xs: 0, md: 6 }
              }}>
                <HeaderText textAlign={"start"}>{t("Projects.title")}</HeaderText>
                <InfoText textAlign={"start"} fontSize={"1.1rem"} color="#004e2b" fWeight={"bold"}>
                  {t("Projects.subtitle")}
                </InfoText>
                <HDButton width={"auto"} clickEvent={() => { handleSubmitProjectButton() }} text={t("Projects.submitProject")} color="black" />
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Container maxWidth="xl" sx={{
        display: "flex",
        flexDirection: "column",
        mt: { xs: 4, sm: 6, md: 9, lg: 12 },
      }}>
        <Box sx={{ width: "100%" }}>
          <H4 textAlign={"start"} >{t("Projects.search")}</H4>
          <Box sx={{  }}>
              <FormControl sx={{
                  width: "100%",
                  display: "flex", 
                  flexDirection: { xs: "column", sm: "row" }, 
                  gap: 2
                }}>
                <TextField
                  sx={{ width: { xs: "100%", sm: "100%", lg: "70%" } }} placeholder={t("Projects.searchPlaceholder")}
                  value={searchQuery} onChange={(event) => { setSearchQuery(event?.target.value) }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ display: searchQuery ? undefined: "none" }}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setSearchQuery("");
                            setFilterActive(false);
                            setSearchType(0)
                          }}
                        >
                          <Close />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                {/* <HDButton type="submit" text={t("Projects.startSearch")} clickEvent={() => { showFilteredProjects() }} marginTop={0} my={1} width={"auto"} /> */}
              </FormControl>
          </Box>
          {/* <FormControl sx={{ width: "100%"}}>
            <RadioGroup value={searchType} onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {handleSearchType(value)}} sx={{flexDirection: {xs: "column", md: "row"}, gap: {xs:0, md: 4}}}>
              <FormControlLabel value="2" id="running-projects" control={<Radio/>} label={<LabelText>{t("Projects.ongoing")}</LabelText>} />
              <FormControlLabel value="3" id="completed-projects" control={<Radio/>} label={<LabelText>{t("Projects.finished")}</LabelText>} />
            </RadioGroup>
          </FormControl> */}
        </Box>
        <Box>
          {filterActive ? (
            <Box sx={{ mt: { xs: 2, sm: 4, md: 6 }, width: "100%" }}>
              <H5 textAlign={"start"} fWeight={"bold"}>{t("Projects.searchResult")}</H5>
              <Box sx={{
                mt: { xs: 2, sm: 4, md: 6 },
                position: "relative",
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                gap: (CardGapPx + "px"),
              }}>
                {displayedFilteredProjects ? displayedFilteredProjects.map((project: Project) => {
                  return (
                    <Box
                      key={project.id}
                      sx={{
                        flexBasis: { xs: "100%", md: "calc(50% - " + CardGapPx + "px)", xl: "calc(32% - " + Math.ceil(CardGapPx / 2) + "px)" }
                      }}
                    >
                      <Box
                        component="img"
                        src={`${process.env.REACT_APP_ASSET_HOST}${project.image_url}`}
                        onClick={() => { handleImageClick(project) }}
                        sx={{
                          width: "100%",
                          maxHeight: "30vh",
                          objectFit: "cover",
                          borderRadius: "0 0 0 0",
                          aspectRatio: "1 / 1",
                          "&:hover": {
                            cursor: "pointer"
                          }
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <H5 textAlign={"start"} color={"#004e2b"} fWeight={"bold"}>
                          {getNameString(project, i18n.language)}
                        </H5>
                        <InfoText textAlign={"start"}>
                          <RichText htmlContent={getDescriptionString(project, i18n.language)} />
                        </InfoText>
                        <InfoTextExa
                          textAlign={"start"}
                          fWeight={"bold"}
                          color={"#004e2b"}
                        >
                          {getStateString(project, i18n.language)}
                          <br />
                          <>
                            <SvgIcon>
                              <PinDrop />
                            </SvgIcon>
                            <Link sx={{ ml: 1 }} href={`https://www.google.com/maps/place/${project.latitude},${project.longitude}`} target="_blank">{t("Project.maps")}</Link>
                          </>

                        </InfoTextExa>
                        <Link href="/project_details" onClick={() => { dispatch(setProject(project)) }}>
                          <InfoTextExa fWeight={"bold"} textDecoration="underline">{t("Projects.show")}</InfoTextExa>
                        </Link>
                        {project.volunteers > 0 && isRunning(project) ? (
                          participant ? (
                            !project.attending_participants_ids.includes(
                              participant.id
                            ) ? (
                              <HDButton
                                text={t("Project.volunteer")}
                                clickEvent={() => {
                                  handleVolunteerButton(0, project.id);
                                }}
                                // width={{ xs: "140px", md: "168px" }}
                                color="#3B3B3B"
                              />
                            ) : (
                              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <HDButton
                                  text={t("Project.deregisteration")}
                                  clickEvent={() => {
                                    handleVolunteerButton(2, project.id);
                                  }}
                                  // width={{ xs: "140px", md: "168px" }}
                                  color="#3B3B3B"
                                />
                              </Box>
                            )
                          ) : (
                            <HDButton
                              text={t("Project.volunteer")}
                              clickEvent={() => {
                                handleVolunteerButton(1);
                              }}
                              width="auto"
                              color="#3B3B3B"
                            />
                          )
                        ) : null}
                      </Box>
                    </Box>
                  );
                }) : <H4>{t("Projects.noOngoingProjects")}</H4>}
              </Box>
              {filteredProjects && filteredProjects.length > itemsPerPage && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 4,
                    width: "inherit"
                  }}
                >
                  <Pagination
                    count={Math.ceil(filteredProjects.length / itemsPerPage)}
                    page={filteredPage}
                    onChange={handleFilteredPageChange}
                  />
                </Box>
              )}
            </Box>) : (
            <Box sx={{ mt: { xs: 2, sm: 4, md: 6 }, width: "100%" }}>
              <H5 textAlign={"start"} fWeight={"bold"}>{t("Projects.ongoing")}</H5>
              <Box
                sx={{
                  mt: { xs: 2, sm: 4, md: 6 },
                  position: "relative",
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  gap: (CardGapPx + "px"),
                }}
              >
                {displayedOngoingProjects ? displayedOngoingProjects.map((project: Project) => {
                  return (
                    <Box
                      key={project.id}
                      sx={{
                        flexBasis: { xs: "100%", md: "calc(50% - " + CardGapPx + "px)", xl: "calc(32% - " + Math.ceil(CardGapPx / 2) + "px)" }
                      }}
                    >
                      <Box
                        component="img"
                        src={`${process.env.REACT_APP_ASSET_HOST}${project.image_url}`}
                        onClick={() => { handleImageClick(project) }}
                        sx={{
                          width: "100%",
                          maxHeight: "30vh",
                          objectFit: "cover",
                          borderRadius: "0 0 0 0",
                          aspectRatio: "1 / 1",
                          "&:hover": {
                            cursor: "pointer"
                          }
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <H5 textAlign={"start"} color={"#004e2b"} fWeight={"bold"}>
                          {getNameString(project, i18n.language)}
                        </H5>
                        <InfoText textAlign={"start"}>
                          <RichText htmlContent={getDescriptionString(project, i18n.language)} />
                        </InfoText>
                        <InfoTextExa
                          textAlign={"start"}
                          fWeight={"bold"}
                          color={"#004e2b"}
                        >
                          {getStateString(project, i18n.language)}
                          <br />
                          <>
                            <SvgIcon>
                              <PinDrop />
                            </SvgIcon>
                            <Link sx={{ ml: 1 }} href={`https://www.google.com/maps/place/${project.latitude},${project.longitude}`} target="_blank">{t("Project.maps")}</Link>
                          </>

                        </InfoTextExa>
                        <Link href="/project_details" onClick={() => { dispatch(setProject(project)) }}>
                          <InfoTextExa fWeight={"bold"} textDecoration="underline">{t("Projects.show")}</InfoTextExa>
                        </Link>
                        {project.volunteers > 0 && isRunning(project) ? (
                          participant ? (
                            !project.attending_participants_ids.includes(
                              participant.id
                            ) ? (
                              <HDButton
                                text={t("Project.volunteer")}
                                clickEvent={() => {
                                  handleVolunteerButton(0, project.id);
                                }}
                                // width={{ xs: "140px", md: "168px" }}
                                color="#3B3B3B"
                              />
                            ) : (
                              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                <HDButton
                                  text={t("Project.deregisteration")}
                                  clickEvent={() => {
                                    handleVolunteerButton(2, project.id);
                                  }}
                                  // width={{ xs: "140px", md: "168px" }}
                                  color="#3B3B3B"
                                />
                              </Box>
                            )
                          ) : (
                            <HDButton
                              text={t("Project.volunteer")}
                              clickEvent={() => {
                                handleVolunteerButton(1);
                              }}
                              width="auto"
                              color="#3B3B3B"
                            />
                          )
                        ) : null}
                      </Box>
                    </Box>
                  )
                }) : (
                  <H4>{t("Projects.noOngoingProjects")}</H4>
                )}
                {ongoingProjects && ongoingProjects.length > itemsPerPage && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 4,
                      width: "inherit"
                    }}
                  >
                    <Pagination
                      count={Math.ceil(ongoingProjects.length / itemsPerPage)}
                      page={ongoingPage}
                      onChange={handleOngoingPageChange}
                    />
                  </Box>
                )}
              </Box>
              <Box sx={{
                pt: { xs: 2, sm: 6, md: 10, lg: 14 },
                pb: { xs: 6, sm: 6, md: 10, lg: 14 },
                width: "100%"
              }}>
                <H5 textAlign={"start"} fWeight={"bold"}>{t("Projects.finished")}</H5>
                <Box sx={{
                  mt: { xs: 2, sm: 4, md: 6 },
                  position: "relative",
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  gap: (CardGapPx + "px"),
                }}>
                  {displayedFinishedProjects ? displayedFinishedProjects.map((project: Project) => {
                    return (
                      <Box
                        key={project.id}
                        sx={{
                          flexBasis: { xs: "100%", md: "calc(50% - " + CardGapPx + "px)", xl: "calc(32% - " + Math.ceil(CardGapPx / 2) + "px)" }
                        }}
                      >
                        <Box
                          component="img"
                          src={`${process.env.REACT_APP_ASSET_HOST}${project.image_url}`}
                          onClick={() => { handleImageClick(project) }}
                          sx={{
                            width: "100%",
                            maxHeight: "30vh",
                            objectFit: "cover",
                            borderRadius: "0 0 0 0",
                            aspectRatio: "1 / 1",
                            "&:hover": {
                              cursor: "pointer"
                            }
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1.5rem",
                          }}
                        >
                          <H5 textAlign={"start"} color={"#004e2b"} fWeight={"bold"}>
                            {getNameString(project, i18n.language)}
                          </H5>
                          <InfoText textAlign={"start"}>
                            <RichText htmlContent={getDescriptionString(project, i18n.language)} />
                          </InfoText>
                          <InfoTextExa
                            textAlign={"start"}
                            fWeight={"bold"}
                            color={"#004e2b"}
                          >
                            {getStateString(project, i18n.language)}
                            <br />
                            {project.teaser ? (
                              <>
                                <SvgIcon>
                                  <PinDrop />
                                </SvgIcon>
                                <Link sx={{ ml: 1 }} href={`https://www.google.com/maps/place/${project.latitude},${project.longitude}`} target="_blank">{t("Project.maps")}</Link>
                              </>
                            ) : null}
                          </InfoTextExa>
                        </Box>
                      </Box>
                    );
                  }) : <H4>{t("Projects.noFinishedProjects")}</H4>}
                </Box>
              </Box>
            </Box>)}
          {finishedProjects && finishedProjects.length > itemsPerPage && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 4,
                width: "inherit"
              }}
            >
              <Pagination
                count={Math.ceil(finishedProjects.length / itemsPerPage)}
                page={finishedPage}
                onChange={handleFinishedPageChange}
              />
            </Box>
          )}
        </Box>
      </Container>
      <Volunteer />
    </Box>
  )
}
