import React from "react";
import styles from "./RichtText.module.css";

interface RichTextProps {
  htmlContent: string;
}

const RichText: React.FC<RichTextProps> = ({ htmlContent }) => (
  <span className={styles.richText} dangerouslySetInnerHTML={{ __html: htmlContent }} />
);

export default RichText;
