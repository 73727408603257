import { Close } from "@mui/icons-material";
import { Backdrop, Box, IconButton, Modal } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { HDButton } from "components/buttons/HDButton";
import CustomText from "components/typography/CustomText";
import H2 from "components/typography/H2";
import { selectVolunteerModal, setVolunteerModal } from "features/projects/projectsSlice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function Volunteer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const open = useAppSelector(selectVolunteerModal)
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(setVolunteerModal(false))
    navigate(0)
  }

  return (
    <Backdrop
      sx={{ backgroundColor: "#707070", zIndex: 1200 }}
      open={open}
      onClick={handleClose}
    >
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: { xs: "40px 10px", md: "80px 180px" },
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: { xs: "340px", md: "650px" },
              justifyContent: { xs: "flex-end", md: "flex-start" },
            }}
          >
            <IconButton sx={{ color: "white" }} onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: { xs: "300px", md: "600px" },
              position: "relative",
              gap: 2,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#004E2B",
                padding: 8,
                borderRadius: "0 0 150px 0",
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <H2 color={"white"}>{t("Volunteer.heading")}</H2>
              <CustomText color={"white"}>{t("Volunteer.text")}</CustomText>
              <HDButton
                text="Alles klar"
                clickEvent={() => {
                  navigate("/");
                }}
                backgroundColor="transparent"
                color="white"
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Backdrop>
  );
}
