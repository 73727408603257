import axios from "axios";
import { ProjectFormData } from "types/interfaces";

const geocode = (formData: Partial<ProjectFormData>) => {
  const { zip, city, street, houseNumber } = formData;
  const systemPartner = process.env.REACT_APP_YM_SYSTEM_PARTNER;
  const securityID = process.env.REACT_APP_YM_SECURITY_ID;
  const data = JSON.stringify({
    type: "Feature",
    properties: {},
    geometry: {
      type: "Point",
      coordinates: [0, 0],
    },
    crs: {
      type: "name",
      properties: {
        name: "urn:ogc:def:crs:OGC:1.3:CRS84",
      },
    },
    searchparams: {
      geocodingType: "GEOCODE",
      coordFormatOut: "GEODECIMAL_POINT",
      isoLocale: "de-DE",
    },
    authentication: {
      channel: "",
    },
    location: {
      country: "Deutschland",
      district: "",
      zip,
      city,
      cityAddOn: "",
      cityPart: "",
      street,
      houseNo: houseNumber,
      singleSlot: "",
    },
  });
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `https://www.yellowmap.de/api_rst/v2/geojson/geocode?systemPartner=${systemPartner}&securityID=${securityID}`,
    headers: {
      authority: "www.yellowmap.de",
      accept: "*/*",
      "accept-language": "de-DE,de;q=0.9,en-US;q=0.8,en;q=0.7",
      "content-type": "application/json",
    },
    data: data,
  };

  return axios.request(config)
};

export default geocode;
