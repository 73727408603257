import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children?: ReactNode,
  color?: String,
  fWeight?: String,
  textAlign?: any,
  mx?: any,
  my?: any,
  border?: any,
  fontSize?: any,
  // any props that come into the component
}

export default function H5({ children, color = "#004E2B", fWeight = "bold", mx, my, border, textAlign= "start", fontSize={xs: "16pt", md: "28px"} }: Props) {
  return (
    <Typography
      variant="h5"
      noWrap
      sx={{
        fontFamily: 'Lexend Exa HM',
        // mx: {xs:"auto", md:`${mx}`},
        my:my,
        fontWeight: `${fWeight}`,
        color: `${color}`,
        textDecoration: "none",
        fontSize: fontSize,
        textAlign: textAlign,
        whiteSpace: "inherit"
      }}
    >
      {children}
    </Typography>
  );
}
