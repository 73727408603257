import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import * as Yup from "yup";
import { HDButton } from "components/buttons/HDButton";
import HDOutlinedInput from "components/HDOutlinedInput";
import { InfoText, NavigationText } from "components/typography";
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "app/axios";
import { ProjectFormData } from "types/interfaces";
import ImageUploader from "./components/ImageUploader";
import ItemSelect from "./components/ItemSelect";
import validationSchema from "./utils/validationSchema";
import teaserHtml from "./utils/teaserHtml";
import geocode from "./utils/geocode";

export function ProjectForm() {
  const initialState: Partial<ProjectFormData> = {
    phone: "",
    name: "",
    street: "",
    houseNumber: "",
    zip: "",
    city: "",
    district: "",
    status: "",
    image: "",
    description: "",
    siteusage: "",
    length: undefined,
    width: undefined,
    distance: undefined,
    surface: "",
    benches: 0,
    fruittrees: 0,
    decorativetrees: 0,
    volunteers: 0,
    datestatus: "",
    info: "",
    latitude: undefined,
    longitude: undefined,
    date: "",
    teaser: "",
  };

  const initialErrorsState: Record<keyof ProjectFormData, string> = {
    phone: "",
    name: "",
    street: "",
    houseNumber: "",
    zip: "",
    city: "",
    district: "",
    status: "",
    image: "",
    description: "",
    siteusage: "",
    length: "",
    width: "",
    distance: "",
    surface: "",
    benches: "",
    fruittrees: "",
    decorativetrees: "",
    volunteers: "",
    datestatus: "",
    info: "",
    latitude: "",
    longitude: "",
    date: "",
    teaser: "",
  };

  const mobile = useMediaQuery("max-width: 900px");
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState(initialErrorsState);
  const navigate = useNavigate();

  const postForm = (additionalData: Partial<ProjectFormData> = {}) => {
    const data = { ...formData, ...additionalData };
    console.log("POST data", data);
    axiosInstance
      .post(
        "projects",
        { data: data },
        {
          headers: { "If-Match": localStorage.getItem("token") },
        }
      )
      .then(() => {
        navigate("/submission_successful");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateForm = () => {
    return validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({ ...initialErrorsState });
        return true;
      })
      .catch((validationErrors) => {
        if (validationErrors instanceof Yup.ValidationError) {
          const newErrors: Record<string, string> = initialErrorsState;
          validationErrors.inner.forEach((error) => {
            if (error.path) {
              newErrors[error.path] = error.message;
            }
          });
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...newErrors,
          }));
        }
        return false;
      });
  };

  const handleTimePickerInput = (newValue: DateTime | null) => {
    if (newValue) {
      const formattedValue = newValue.toFormat("yyyy-MM-dd");
      setFormData({ ...formData, date: formattedValue });
    }
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setErrors((prevData) => ({
      ...prevData,
      [name]: "",
    }));
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      teaser: getTeaserHtml(),
    }));
  };

  const getTeaserHtml = () => {
    return teaserHtml({
      date: formData.date,
      street: formData.street,
      houseNumber: formData.houseNumber,
      zip: formData.zip,
      city: formData.city,
      district: formData.district,
    });
  };

  const handleSubmit = () => {
    validateForm().then((isValid) => {
      if (isValid) {
        if (!!formData.latitude && !!formData.longitude) {
          postForm();
        } else {
          geocode(formData)
            .then((response) => {
              const { data } = response;
              if (data.bbox) {
                postForm({ latitude: data.bbox[1], longitude: data.bbox[0] });
              } else {
                console.log("No bbox found");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log("Form not submitted");
      }
    });
  };

  return (
    <Container className="project-form" sx={{ mt: 4 }}>
      <NavigationText fontSize={"1.75rem"} textAlign={"start"}>
        {t("submitForm.title")}
      </NavigationText>

      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", gap: 4, mt: 2 }}
      >
        <Box>
          <NavigationText fontSize={"1.5rem"}>
            {t("submitForm.info1")}
          </NavigationText>
          <NavigationText fontSize={"1.5rem"}>
            {t("submitForm.info2")}
          </NavigationText>
          <NavigationText fontSize={"1.5rem"}>
            {t("submitForm.info3")}
          </NavigationText>
        </Box>

        <Box>
          <NavigationText fontSize={"1.5rem"}>
            {t("submitForm.general")}
          </NavigationText>
        </Box>

        <Box sx={{ width: "100%" }}>
          <NavigationText>{`${t("submitForm.phone")} *`}</NavigationText>
          <FormControl
            required
            sx={{
              width: { xs: "100%", md: "66%" },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <NavigationText>+49</NavigationText>
            <HDOutlinedInput
              name="phone"
              sx={{ width: { xs: "100%", md: "66%" } }}
              onChange={handleInputChange}
              error={t(errors.phone)}
            />
          </FormControl>
          <InfoText>{t("submitForm.telNote")}</InfoText>
        </Box>
        <NavigationText>{t("submitForm.projectStatus")}</NavigationText>
        <Box sx={{ width: "100%" }}>
          <NavigationText>{`${t("submitForm.name")} *`}</NavigationText>
          <FormControl required sx={{ width: "100%" }}>
            <HDOutlinedInput
              name="name"
              onChange={handleInputChange}
              error={t(errors.name)}
            />
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "inherit", gap: 2 }}>
          <NavigationText>Location *</NavigationText>
          <Box sx={{ display: "flex", gap: 2 }}>
            <FormControl required sx={{ width: "60ch" }}>
              <HDOutlinedInput
                name="street"
                onChange={handleInputChange}
                error={t(errors.street)}
              />
            </FormControl>
            <FormControl required sx={{ width: "30ch" }}>
              <HDOutlinedInput
                name="houseNumber"
                onChange={handleInputChange}
                error={t(errors.houseNumber)}
              />
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <FormControl required sx={{ width: "30ch" }}>
              <HDOutlinedInput
                name="zip"
                onChange={handleInputChange}
                error={t(errors.zip)}
              />
            </FormControl>
            <FormControl sx={{ width: "60ch" }}>
              <HDOutlinedInput
                name="city"
                onChange={handleInputChange}
                error={t(errors.city)}
              />
            </FormControl>
          </Box>
          <Box sx={{ display: "flex" }}>
            <FormControl required sx={{ width: "calc(90ch + 16px)" }}>
              <HDOutlinedInput
                name="district"
                onChange={handleInputChange}
                error={t(errors.district)}
              />
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <NavigationText>{t("submitForm.coordinates")}</NavigationText>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <FormControl required sx={{ width: "45ch" }}>
                <HDOutlinedInput
                  name="latitude"
                  onChange={handleInputChange}
                  error={t(errors.latitude)}
                />
              </FormControl>
              <FormControl required sx={{ width: "45ch" }}>
                <HDOutlinedInput
                  name="longitude"
                  onChange={handleInputChange}
                  error={t(errors.longitude)}
                />
              </FormControl>
            </Box>
          </Box>
        </Box>

        <Box>
          <NavigationText>{`${t("submitForm.status.title")} *`}</NavigationText>
          <FormControl required>
            <RadioGroup
              name="status"
              sx={{ flexDirection: "column" }}
              onChange={handleInputChange}
            >
              <FormControlLabel
                value="idea"
                id="status-idea"
                control={<Radio />}
                label={t("submitForm.status.idea")}
              />
              <FormControlLabel
                value="draft"
                id="status-draft"
                control={<Radio />}
                label={t("submitForm.status.draft")}
              />
              <FormControlLabel
                value="planned"
                id="status-planned"
                control={<Radio />}
                label={t("submitForm.status.final")}
              />
            </RadioGroup>
            {!!errors.status && (
              <FormHelperText error>{t(errors.status)}</FormHelperText>
            )}
          </FormControl>
        </Box>

        <NavigationText fontSize={"1.75rem"}>
          {t("submitForm.details")}
        </NavigationText>
        <Box>
          <NavigationText>{t("submitForm.image")}</NavigationText>
          <ImageUploader formData={formData} setFormData={setFormData} />
          {!!errors.image && (
            <FormHelperText error>{t(errors.image)}</FormHelperText>
          )}
        </Box>

        <Box sx={{ width: { xs: "100%", md: "66%" } }}>
          <NavigationText>{`${t("submitForm.siteusage")} *`}</NavigationText>
          <FormControl required sx={{ width: "100%" }}>
            <HDOutlinedInput
              name="siteusage"
              placeholder={t("submitForm.projectDescPlaceholder")}
              multiline
              minRows={4}
              onChange={handleInputChange}
              error={t(errors.siteusage)}
            />
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <NavigationText>{`${t("submitForm.measurements")} *`}</NavigationText>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <FormControl required sx={{ width: "45ch" }}>
              <HDOutlinedInput
                type="number"
                inputProps={{ min: 0 }}
                name="length"
                onChange={handleInputChange}
                error={t(errors.length)}
              />
            </FormControl>
            <FormControl required sx={{ width: "45ch" }}>
              <HDOutlinedInput
                type="number"
                inputProps={{ min: 0 }}
                name="width"
                onChange={handleInputChange}
                error={t(errors.width)}
              />
            </FormControl>
          </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
          <NavigationText>{t("submitForm.distance")}</NavigationText>
          <FormControl required sx={{ width: "100%" }}>
            <HDOutlinedInput
              type="number"
              inputProps={{ min: 0 }}
              name="distance"
              placeholder={t("submitForm.distancePlaceholder")}
              onChange={handleInputChange}
              error={t(errors.distance)}
            />
          </FormControl>
        </Box>

        <Box sx={{ width: "100%" }}>
          <NavigationText>{`${t("submitForm.underground")} *`}</NavigationText>
          <FormControl sx={{ width: "100%" }}>
            <HDOutlinedInput
              name="surface"
              placeholder={t("submitForm.undergroundPlaceholder")}
              onChange={handleInputChange}
              error={t(errors.surface)}
            />
          </FormControl>
        </Box>

        <NavigationText fontSize={"1.75rem"}>
          {t("submitForm.implementation")}
        </NavigationText>

        <Box sx={{ width: "100%" }}>
          <NavigationText>{t("submitForm.description")}</NavigationText>
          <FormControl sx={{ width: "100%" }}>
            <HDOutlinedInput
              name="description"
              multiline
              minRows={4}
              onChange={handleInputChange}
              placeholder={t("submitForm.descPlaceholder")}
              error={t(errors.description)}
            />
          </FormControl>
        </Box>

        <Box>
          <NavigationText>{t("submitForm.wishlist")}</NavigationText>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "space-between", md: "start" },
              pt: 2,
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                width: { md: "33%" },
              }}
            >
              <FormControl required sx={{ width: "50%" }}>
                <InputLabel id="bench-label">
                  {t("submitForm.benches")}
                </InputLabel>
                <ItemSelect
                  name="benches"
                  label={t("submitForm.benches")}
                  id="bench-select"
                  labelId="bench-label"
                  defaultValue={0}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="fruittree-label">
                  {t("submitForm.trees")}
                </InputLabel>
                <ItemSelect
                  name="fruittrees"
                  label={t("submitForm.trees")}
                  id="fruittree-select"
                  labelId="fruittree-label"
                  defaultValue={0}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                pb: 2,
                width: { md: "33%" },
              }}
            >
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="ornamenttree-label">
                  {t("submitForm.ornamentTrees")}
                </InputLabel>
                <ItemSelect
                  name="decorativetrees"
                  label={t("submitForm.ornamentTrees")}
                  id="ornamenttree-select"
                  labelId="ornamenttree-label"
                  defaultValue={0}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl sx={{ width: "50%" }}>
                <InputLabel id="helper-label">
                  {t("submitForm.helpers")}
                </InputLabel>
                <ItemSelect
                  name="volunteers"
                  label={t("submitForm.helpers")}
                  id="helper-select"
                  labelId="helper-label"
                  defaultValue={0}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", md: "66%" },
          }}
        >
          <NavigationText>{t("submitForm.date.title")}</NavigationText>
          <FormControl error={!!errors.date} required>
            <LocalizationProvider
              adapterLocale={i18n.language}
              dateAdapter={AdapterLuxon}
            >
              {mobile ? (
                <MobileDatePicker
                  format="yyyy-MM-dd"
                  disablePast={true}
                  onChange={handleTimePickerInput}
                />
              ) : (
                <DesktopDatePicker
                  format="yyyy-MM-dd"
                  disablePast={true}
                  onChange={handleTimePickerInput}
                />
              )}
            </LocalizationProvider>
            {!!errors.date && (
              <FormHelperText error>{t(errors.date)}</FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <RadioGroup
              name="datestatus"
              sx={{ flexDirection: "row", gap: 2 }}
              onChange={handleInputChange}
            >
              <FormControlLabel
                value="orientation"
                id="orientation-date"
                control={<Radio />}
                label={t("submitForm.date.orientation")}
              />
              <FormControlLabel
                value="fixed"
                id="fixed-date"
                control={<Radio />}
                label={t("submitForm.date.planned")}
              />
            </RadioGroup>
            {!!errors.datestatus && (
              <FormHelperText error>{t(errors.datestatus)}</FormHelperText>
            )}
          </FormControl>
        </Box>

        <Box sx={{ width: "100%" }}>
          <NavigationText>{t("submitForm.otherInfo")}</NavigationText>
          <FormControl sx={{ width: "100%" }}>
            <HDOutlinedInput
              name="info"
              placeholder=""
              multiline
              minRows={4}
              onChange={handleInputChange}
              error={t(errors.info)}
            />
          </FormControl>
        </Box>

        <HDButton
          text={t("submitForm.submitButton")}
          clickEvent={handleSubmit}
        />

        <Box sx={{ width: "100%" }}>
          <NavigationText>{t("submitForm.infoNeeded.title")}</NavigationText>
          <Box sx={{ p: "16px 0px 0px 16px" }}>
            <NavigationText>
              {t("submitForm.infoNeeded.underground")}
            </NavigationText>
            <NavigationText>{t("submitForm.infoNeeded.permit")}</NavigationText>
            <NavigationText>{t("submitForm.infoNeeded.care")}</NavigationText>
            <NavigationText>
              {t("submitForm.infoNeeded.agreements")}
            </NavigationText>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
