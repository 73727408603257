import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  MenuItem,
  IconButton,
  Button,
  useMediaQuery,
  SvgIcon,
  Link,
  Container,
} from "@mui/material";
import { Menu, Search, Close, Person } from '@mui/icons-material';
// import { AccountMenu, LoginButton, ShoppingCartButton, NavigationProfileButton } from "./components/index";
import { useNavigate } from "react-router-dom";
import styles from "./HeaderNavbar.module.css";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { HDDivider } from "components/HDDivider";
import { ReturnUrlContext } from "components/ReturnUrlContext";

import H2 from "components/typography/H2";
import Nav from "components/typography/Nav";
import { RootState } from "app/store";



interface Props {
  loggedIn: boolean;
}

export function capitalizeFirstLetter(string: string | undefined) {
  if (typeof string === "string") return string.charAt(0).toUpperCase() + string.slice(1);
}

export function HeaderNavbar({ loggedIn }: Props) {
  // const [user, setUser] = useState()
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (loggedIn) {
    }
  }, [loggedIn, dispatch]);

  const [navIsOpen, setNavIsOpen] = React.useState(false);
  const { setReturnUrl } = useContext(ReturnUrlContext);
  // const parsedUserRole = capitalizeFirstLetter();
  const navigate = useNavigate();
  const handleClickAccordionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setNavIsOpen(!navIsOpen);
  };

  const handleCloseAccordionMenu = () => {
    setNavIsOpen(false);
  };

  const handleNavigate = () => {
    setReturnUrl("/my_section");
    navigate("/login");
  };
  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };
  // console.log(matches799)
  const mobile = useMediaQuery('(max-width: 900px)')
  const { t, i18n } = useTranslation();
  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(`${lang}`)
  }
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const pages = [
    {
      label: () => t("HeaderNavbar.homepage"),
      route: "/",
      className: styles.hoverLine,
      showCheck: () => true,
    },
    {
      label: () => t("HeaderNavbar.newsfeed"),
      route: "newsfeed",
      className: styles.hoverLine,
      showCheck: () => true,
    },
    {
      label: () => t("HeaderNavbar.projectlist"),
      route: "projectlist",
      className: styles.hoverLine,
      showCheck: () => true,
    },
  ];

  return (
    <div>
      <Box sx={{ pt: { xs: "96px", md: "80px" } }}>
        <AppBar
          position="fixed"
          sx={{
            zIndex: 10002,
            backgroundColor: "white",
            maxWidth: "100vw",
            boxShadow: "none",
            left: 0,
            borderBottom: 1,
            borderColor: "gray",
          }}
        >
          <Box
            sx={{
              height: { xs: "80px", md: "64px" },
              padding: { xs: "16px 0px 0px", md: "16px 0px 0px" },
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              position: "relative",
              width: "100%",
              maxWidth: "100%",
              gap: { xs: 2, md: 6, lg: 8, xl: 14 },
            }}
          >
            <Container
              maxWidth="xl"
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box component="a" href="/">
                <Box
                  component="img"
                  src="/images/HeidelbergMaterials.svg"
                  width={139}
                  height={42}
                />
              </Box>

              <Box
                sx={{
                  display: mobile ? "none" : "flex",
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "white",
                    boxShadow: "none",
                  },
                }}
              >
                {pages.map((page) => (
                  <Link
                    key={page.route}
                    href={page.route}
                    sx={{
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline 1px #00DD39",
                        backgroundColor: "white",
                      },
                    }}
                  >
                    <MenuItem
                      disableRipple
                      key={page.route}
                      onClick={() => {
                        navigate(`${page.route}`);
                      }}
                      sx={{
                        "&:hover": {
                          textDecoration: "underline 2px #00DD39",
                          backgroundColor: "white",
                        },
                      }}
                    >
                      <Nav>{page.label()}</Nav>
                    </MenuItem>
                  </Link>
                ))}
              </Box>

              <Box
                sx={{
                  justifyContent: "inherit",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <IconButton
                  onClick={handleClickAccordionMenu}
                  size="small"
                  sx={{
                    display: mobile ? "inherit" : "none",
                  }}
                >
                  <SvgIcon
                    sx={{
                      fontSize: "36px",
                    }}
                  >
                    {navIsOpen ? <Close /> : <Menu />}
                  </SvgIcon>
                  {/* <NavigationText highlighted>Menü</NavigationText> */}
                </IconButton>

                {/* Language switcher */}
                <Box
                  sx={{
                    display: mobile ? "none" : "flex",
                    marginRight: 4,
                  }}
                >
                  <Button
                    disableRipple
                    variant="text"
                    onClick={() => handleLanguageChange("de")}
                    sx={{
                      textDecoration: i18n.language.includes("de")
                        ? "underline"
                        : "none",
                      opacity: i18n.language.includes("de") ? "1" : "0.5",
                      minWidth: 0,
                      "&:hover": { backgroundColor: "white" },
                    }}
                  >
                    <Nav>DE</Nav>
                  </Button>
                  <Button
                    disableRipple
                    variant="text"
                    onClick={() => handleLanguageChange("en")}
                    sx={{
                      textDecoration: i18n.language.includes("de")
                        ? "none"
                        : "underline",
                      opacity: i18n.language.includes("de") ? "0.5" : "1",
                      minWidth: 0,
                      "&:hover": { backgroundColor: "white" },
                    }}
                  >
                    <Nav>EN</Nav>
                  </Button>
                </Box>

                <IconButton
                  disableRipple
                  onClick={handleNavigate}
                  sx={{
                    height: "32px",
                    transitionProperty: "all",
                    transitionDelay: "0s",
                    transitionDuration: ".4s",
                    transitionTimingFunction: "linear",
                    backgroundColor: "white",
                    display: "flex",
                    gap: 2,
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                  // aria-controls={navIsOpen ? "account-menu" : undefined}
                  // aria-haspopup="true"
                  // aria-expanded={navIsOpen ? "true" : "false"}
                >
                  {/* <NavigationText>Login</NavigationText> */}
                  {mobile ? null : isAuthenticated ? (
                    <Nav>{t("MySection.title")}</Nav>
                  ) : (
                    <Nav>Login</Nav>
                  )}
                  <Box
                    component="img"
                    src="/images/ProfilIcon.svg"
                    sx={{
                      width: { xs: "18px", md: "14px" },
                      paddingTop: 0,
                    }}
                  />
                </IconButton>
              </Box>
            </Container>
            {/* Left aligned menue items */}

            {/* {pages.map((page, index) =>
              page.leftNav && page.showCheck(loggedIn) ? (
                page.label === "KONTAKT" ? (
                  <Box
                    key={page.route}
                    className={page.className}
                    sx={{
                      display: { xs: "none", lg: "flex" },
                    }}
                  >
                    <Button
                      key={page.route}
                      onClick={() => {
                        navigate("/#contact", {
                          state: { targetId: "contact-section" },
                        });
                      }}
                      // style={{
                      //   textDecoration: "none",
                      // }}
                      className={styles.underline}
                    >
                      <MediumText fWeight="bold" color="#FFFFFF" padding={page.textPadding}>
                        {page.label}
                      </MediumText>
                    </Button>
                  </Box>
                ) : (
                  <Box
                    key={page.route}
                    className={page.className}
                    sx={{
                      display: { xs: "none", lg: "flex" },
                    }}
                  >
                    <Link
                      key={page.route}
                      // onClick={handleCloseNavMenu}
                      to={page.route}
                      style={{
                        textDecoration: "none",
                      }}
                      className={styles.underline}
                    >
                      <MediumText fWeight="bold" color="#FFFFFF" padding={page.textPadding}>
                        {page.label}
                      </MediumText>
                    </Link>
                  </Box>
                )
              ) : null
            )} */}

            {/* center aligned menue items */}

            {/* right aligned menue items */}

            {/* ~~~~~~~~~~~~~~ smartphone view icons ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
          </Box>
        </AppBar>
      </Box>

      {/* background */}
      <Box
        onClick={handleCloseAccordionMenu}
        sx={{
          display: navIsOpen ? "inherit" : "none",
          position: "fixed",
          width: "100%",
          height: "100%",
          backgroundColor: "#0007",
          zIndex: 10000,
        }}
      />

      <Box
        sx={{
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          top: 80,
          right: "0px",
          zIndex: 10001,
          transitionProperty: "all",
          transitionDelay: "0s",
          transitionDuration: ".4s",
          transitionTimingFunction: "linear",
          width: { xs: navIsOpen ? "95%" : "0px", md: "95%" },
          height: navIsOpen ? "80vh" : "0px",
          backgroundColor: "white",
          transform: "translateX(calc(-100% - var(--space-sm)))",
          visibility: navIsOpen ? "visible" : "hidden",
          borderRadius: "0 0 0 50px",
          alignItems: "center",
          justifyContent: "space-around",
        }}
        onClick={handleCloseAccordionMenu}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 1,
            ml: 1,
            justifyContent: "inherit",
            height: "40%",
          }}
        >
          {pages.map((page) => (
            <Link
              key={page.route}
              href={page.route}
              sx={{
                textDecoration: "none",
              }}
            >
              <MenuItem
                disableRipple
                key={page.route}
                onClick={handleClickAccordionMenu}
              >
                <H2>{page.label()}</H2>
              </MenuItem>
            </Link>
          ))}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Button
            disableRipple
            variant="text"
            onClick={(ev: any) => {handleLanguageChange("de"); ev.stopPropagation();}}
            sx={{
              textDecoration: i18n.language.includes("de")
                ? "underline"
                : "none",
              opacity: i18n.language.includes("de") ? "1" : "0.5",
              minWidth: 0,
            }}
          >
            <Nav>DE</Nav>
          </Button>
          <Button
            disableRipple
            variant="text"
            onClick={(ev: any) => {handleLanguageChange("en"); ev.stopPropagation();}}
            sx={{
              textDecoration: i18n.language.includes("de")
                ? "none"
                : "underline",
              opacity: i18n.language.includes("de") ? "0.5" : "1",
              minWidth: 0,
            }}
          >
            <Nav>EN</Nav>
          </Button>
        </Box>
      </Box>
      <HDDivider my={0} />
    </div>
  );
}
