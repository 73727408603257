import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "app/axios";
import { RootState } from "app/store";
import { AuthState, LoginCredentials, Participant } from "types/interfaces";

const initialState: AuthState = {
  isAuthenticated: false,
  participant: null,
  token: null,
  loading: false,
  error: null,
  permissionsMissing: false,
};

const storedToken = localStorage.getItem("token");

if (storedToken) {
  initialState.isAuthenticated = true;
}

export const login = createAsyncThunk(
  "auth/login",
  async (credentials: LoginCredentials, { rejectWithValue }) => {
    const response = await axiosInstance.post("login", credentials);
    if (response.status === 200) {
      const { token, data } = response.data;
      console.log(data)
      const participant: Participant = {
        id: data.participant.public_id,
        email: data.participant.email,
        firstname: data.participant.firstname,
        lastname: data.participant.lastname,
      };
      localStorage.setItem("token", token);
      return { token, participant };
    } else {
      return rejectWithValue(response.data.status);
    }
  }
);

export const fetchParticipant = createAsyncThunk(
  "auth/fetchParticipant",
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    if (token) {
      const response = await axiosInstance.post("login", { token });
      if (response.status === 200) {
        const { data } = response;
        const participant: Participant = {
          id: data.participant.public_id,
          email: data.participant.email,
          firstname: data.participant.firstname,
          lastname: data.participant.lastname,
        };
        return participant;
      } else {
        return rejectWithValue(response.data.status);
      }
    } else {
      return rejectWithValue("No token found");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.participant = null;
    },
    setPermissionMissing: (state, action: PayloadAction<boolean>) => {
      state.permissionsMissing = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        login.fulfilled,
        (
          state,
          action: PayloadAction<{ token: string; participant: Participant }>
        ) => {
          state.isAuthenticated = true;
          console.log(action.payload.participant)
          state.participant = action.payload.participant;
          state.token = action.payload.token;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      })
      .addCase(fetchParticipant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchParticipant.fulfilled, (state, action) => {
        state.loading = false;
        state.participant = action.payload;
      })
      .addCase(fetchParticipant.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch participant data";
      });
  },
});

export const { logout, setPermissionMissing } = authSlice.actions;
export const selectParticipantId = (state: RootState) => state.auth.participant?.id || undefined;
export const selectParticipant = (state: RootState) => state.auth.participant || undefined;

export default authSlice.reducer;
