import {
  InputAdornment,
  FormControl,
  IconButton,
  TextField,
  Box,
} from "@mui/material";
import styles from "../Login.module.css";
import { HeaderText, InfoText } from "../../../components/typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { login, setPermissionMissing } from "features/auth/authSlice";
import { RootState } from "app/store";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { HDButton } from "components/buttons/HDButton";
import { useTranslation } from "react-i18next";
import { ReturnUrlContext } from "components/ReturnUrlContext";

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state: RootState) => state.auth.loading);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { returnUrl, setReturnUrl } = useContext(ReturnUrlContext);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleLogin = () => {
    dispatch(login({ email, code })).then(function (ev) {
      switch(ev.type) {
        case "auth/login/fulfilled":
          dispatch(setPermissionMissing(false));
          navigate(returnUrl);
          break;
        case "auth/login/rejected":
          setError(t("Loginform.loginRejected"));
          break;
      }
    });
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { lg: "50%", md: "66%", xs: "100%" },
        gap: 3,
      }}
    >
      <HeaderText textAlign="flex-start"> {t("Loginform.welcome")}</HeaderText>
      <InfoText fontSize={"1rem"} textAlign="flex-start">{t("Loginform.supportWelcome")}<br/>{t("Loginform.notice")}</InfoText>

      <FormControl
        sx={{
          marginRight: { xs: 1, md: 6 },
          display: "flex",
        }}
      >
        <InfoText fWeight="bold" textAlign="start" mt={1}>
          {t("Loginform.emailAddress")}
        </InfoText>
        <TextField
          error={error.length > 0}
          type="email"
          placeholder={t("Loginform.emailAddress")}
          value={email}
          onChange={(event) => {
            setError("");
            setEmail(event.target.value);
          }}
          color="secondary"
          name={"email"}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleLogin();
            }
          }}
        />
      </FormControl>
      <FormControl
        sx={{
          marginRight: { xs: 1, md: 6 },
          display: "flex",
        }}
      >
        <InfoText fWeight="bold" textAlign="start" mt={1}>
          Code
        </InfoText>
        <TextField
          sx={{
            marginBottom: 4,
          }}
          error={error.length > 0}
          helperText={error}
          type={showPassword ? "text" : "password"}
          placeholder="Code"
          value={code}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            setError("");
            setCode(event.target.value);
          }}
          name={"code"}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleLogin();
            }
          }}
        />
        <HDButton
          // width="auto"
          paddingLeft={2}
          clickEvent={handleLogin}
          loading={loading}
          text={t("Loginform.login")}
        />
      </FormControl>
    </Box>
  );
}
