import { Box, Container, Link, SvgIcon } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { HDButton } from "components/buttons/HDButton";
import { HeaderText, InfoText, NavigationText } from "components/typography";
import H4 from "components/typography/H4";
import { logout, selectParticipant } from "features/auth/authSlice";
import { useTranslation } from "react-i18next";
import fillerImage from "assets/img/Heidelberg_Materials_Footer.jpg"
import H5 from "components/typography/H5";
import H2 from "components/typography/H2";
import Description from "components/typography/Description";
import InfoTextExa from "components/typography/InfoTextExa";
import { useNavigate } from "react-router-dom";
import { Participant, Project } from "types/interfaces";
import { emptyProject, getStateString, selectParticipantProjects, selectProjects, setProject } from "features/projects/projectsSlice";
import RichText from "components/RichText";
import { useEffect, useState } from "react";
import { PinDrop } from "@mui/icons-material";

export function MySection() {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    navigate("/")
  }
  const [volunteerProjects, setVolunteerProjects] = useState<Project[]>();
  const participant = useAppSelector(selectParticipant) as Participant;
  const submittedProjects = useAppSelector(selectParticipantProjects);
  const projects = useAppSelector(selectProjects);
  useEffect(() => {
    if(projects){
      const volunteer = projects.filter(((project) => project.attending_participants_ids.includes(participant.id)))
      setVolunteerProjects([...volunteer])
    }

  }, [projects])
	const handleShowProject = (project: Project) => {
    dispatch(setProject(project))
    navigate("/project_details")
  }
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        marginBottom: "20px",
      }}
    >
      <Box
        sx={{
          height: { xs: "250px", md: "80vh" },
          backgroundColor: "#E6E6DF",
          borderRadius: { xs: "0 0 50px 0", md: "0 0 150px 0" },
          width: { xs: "100vw", md: "400px" },
          ml: { xs: -3, md: 0 },
          display: "flex",
        }}
      >
        <Box
          sx={{
            padding: { xs: "20px 0px 0px 50px", md: "50px 90px 0px 30px" },
            display: "flex",
            flexDirection: "column",
            gap: { xs: 1, md: 2 },
          }}
        >
          <H2>{t("MySection.title")}</H2>
          <Box>
            <Description>{t("MySection.name")}</Description>
            <H5 fWeight={"bold"}>
              {participant.firstname + " " + participant.lastname}
            </H5>
          </Box>
          <HDButton
            text={t("MySection.logout")}
            clickEvent={handleLogout}
            color="#3B3B3B"
            backgroundColor="transparent"
            width={{xs: "fit-content", sm: "auto"}}
          />
        </Box>
      </Box>

      <Box>
        {submittedProjects && submittedProjects.length > 0 ? (
          <Box sx={{ padding: { xs: "30px 0px", md: "100px 54px 20px" } }}>
            <H4>{t("MySection.submitted")}</H4>
          </Box>
        ) : null}
        <Grid2
          key="submittedProjects-grid"
          container
          spacing={4}
          sx={{ padding: { md: "0px 54px" } }}
        >
          {submittedProjects && submittedProjects.length > 0 ? (
            submittedProjects.map((project) => {
              return (
                <Grid2
                  key={project.id}
                  md={12}
                  xl={6}
                  sx={{
                    width: "100%",
                    marginTop: 4,
                  }}
                >
                  <Box
                    sx={{ 
                      display: "flex",
                      flexDirection: "column", 
                      gap: 3,
                      width: "100%",
                    }}
                  >
                    <Box
                      component="img"
                      src={`${process.env.REACT_APP_ASSET_HOST}${project.attributes.image}`}
                      sx={{
                        objectFit: "cover",
                        maxHeight: "40vh",
                        width: "100%",
                        aspectRatio: "1 / 1",
                      }}
                    />
                    <H5 fWeight={"bold"}>{project.attributes.name}</H5>
                    <Box sx={{ maxWidth: { xs: "286px", md: "400px" } }}>
                      <InfoText>
                        <RichText
                          htmlContent={
                            project.attributes.description &&
                            project.attributes.description.length > 256
                              ? project.attributes.description.slice(0, 256) +
                                "..."
                              : project.attributes.description
                          }
                        />
                      </InfoText>
                      <InfoTextExa color={"#004E2B"} fWeight="bold">
                        <RichText htmlContent={project.human_status} />
                      </InfoTextExa>
                    </Box>
                  </Box>
                </Grid2>
              );
            })
          ) : (
            <Box
              sx={{ padding: { xs: "30px 0 0 20px", md: "100px 0 0 20px" } }}
            >
              <H4>{t("MySection.noneSubmitted")}</H4>
            </Box>
          )}
        </Grid2>
        {volunteerProjects ? (
          <Box sx={{ padding: { xs: "30px 0px", md: "100px 54px 20px" } }}>
            <H4>{t("MySection.volunteer")}</H4>
          </Box>
        ) : null}
        <Grid2
          key="volunteeringProjects-grid"
          container
          spacing={4}
          sx={{ padding: { md: "0px 54px" } }}
        >
          {volunteerProjects && volunteerProjects.length > 0 ? (
            volunteerProjects.map((project) => (
              <Grid2
                key={project.id}
                md={12}
                xl={6}
                sx={{
                  width: "100%",
                  marginTop: 4,
                  marginBottom: 12,
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_ASSET_HOST}${project.image_url}`}
                    onClick={() => {
                      handleShowProject(project);
                    }}
                    sx={{
                      objectFit: "cover",
                      maxHeight: "40vh",
                      width: "100%",
                      aspectRatio: "1 / 1",

                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // height: "100%",
                    gap: "1.5rem",
                    // maxWidth: { xs: "286px", md: "400px" },
                  }}
                >
                  <H5 textAlign={"start"} color={"#004e2b"} fWeight={"bold"}>
                    {project.name}
                  </H5>
                  <InfoText textAlign={"start"}>
                    <RichText
                      htmlContent={getStateString(project, i18n.language)}
                    />
                  </InfoText>
                  <InfoTextExa
                    textAlign={"start"}
                    fWeight={"bold"}
                    color={"#004e2b"}
                  >
                    {getStateString(project, i18n.language)}
                    <br />
                    {project.teaser ? (
                      <>
                        <SvgIcon>
                          <PinDrop />
                        </SvgIcon>
                        <Link
                          sx={{ ml: 1 }}
                          href={`https://www.google.com/maps/place/${project.latitude},${project.longitude}`}
                          target="_blank"
                        >
                          {t("Project.maps")}
                        </Link>
                      </>
                    ) : null}
                  </InfoTextExa>
                  <HDButton
                    text={t("MySection.show")}
                    clickEvent={() => {
                      handleShowProject(project);
                    }}
                    width={"193px"}
                    color="#3B3B3B"
                  />
                </Box>
              </Grid2>
            ))
          ) : (
            <Box sx={{ padding: "0 0 0 20px" }}>
              <H4>{t("MySection.notVolunteering")}</H4>
            </Box>
          )}
        </Grid2>
      </Box>
    </Container>
  );
}
