import { Box, Container } from "@mui/material"
import { HDButton } from "components/buttons/HDButton"
import { InfoText } from "components/typography"
import { parsePath, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";
import H1 from "components/typography/H1";
import CustomText from "components/typography/CustomText";


interface Props {
  firstImage?: Boolean;
  navigatePath: string;
  navigateText: string;
  backgroundImage: string;
  labelText: string;
  infoText1?: string;
  infoText2?: string;
}


export function SlideBox({ 
    firstImage,
    navigatePath,
    navigateText,
    backgroundImage,
    labelText,
    infoText1,
    infoText2,
  }: Props
){
  const { t } = useTranslation();
  const navigate = useNavigate();
  const path = parsePath(navigatePath);
  const handleButtonClick = (path: string) => {
    if (path === "#info"){
        const navHeight = 100;
        const projectInfo = document.getElementById("homepageInfo");
        window.scrollTo({
          top: (projectInfo?.offsetTop || navHeight) - navHeight,
          behavior: 'smooth'
        });
    } else {
      navigate(path)
    }
  }
  return(
    <Box>
      <Box sx={{
          width: "100%",
          position: "relative",
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          maxHeight: "74vh",
          minHeight: "74vh",
      }}>
        <Container 
          maxWidth="xl" 
          sx={{
            position: "relative",
            flexGrow: 1,
            display: "flex",
        }}>
          <Box sx={{
            position: "relative",
            width: "100%",
          }}>
            {/* green background box */}

            <Box sx={{
              display: firstImage ? "block": "none",
              position: "absolute",
              bottom: {md: 12, xl: 30},
              left: "220px",
              height: {xs: 0, md: 340, xl: 340 },
              width: {xs: 0, md: 460, xl: 500 },
              borderRadius: "0 0 100px 0",
              backgroundColor: "#004E2B"
            }}>
            </Box>
            
            {/* main text box */}
            <Box sx={{
              position: "absolute",
              bottom: {xs: 0, md: 60, xl: 80},
              left: {xs: -24, md: 0, xl: 0 },
              margin: {xs: 0, md: 0},
              height: {xs: 420, md: 450, xl: 420 },
              width: {xs: 400, md: 560, xl: 620 },
              maxHeight: "100vh",
              maxWidth: "92vw",
              minWidth: "280px",
              borderRadius: {xs: "0 140px 0 0", xl: firstImage ? "0 140px 0 0": "0 0 0 140px" },
              backgroundColor: firstImage ? "#FF644E": "#004E2B",
              overflow: "hidden",
            }}>
              <Box sx={{
                margin: 0,
                padding: {
                  xs: "40px 30px 20px 20px", 
                  xl: (firstImage ? 
                    "30px 40px 20px 60px" :
                    "30px 10px 20px 80px" ), 
                },
                display: {md: "flex"},
                flexDirection: "column",
                gap: 2,
                overflow: "hidden",
              }}>
                <Box>
                  {firstImage ? <></> : <InfoText color={"white"} >150 years of progress</InfoText>}
                  <H1 fontSize={{xs: "2rem", md: "3rem"}} color={"white"} fWeight={"bold"}>{
                    t(labelText)
                  }</H1>
                </Box>
                <Box>
                  <InfoText color={"white"} fontSize={{xs: "0.94rem", lg: "1rem"}}>{infoText1 ? t(infoText1): ""}</InfoText>
                  <InfoText color={"white"} fontSize={{xs: "0.94rem", lg: "1rem"}}>{infoText2 ? t(infoText2): ""}</InfoText>
                </Box>
                <HDButton
                  clickEvent={() => {handleButtonClick(navigatePath)}}
                  text={t(navigateText)}
                  backgroundColor="transparent"
                  color="white"
                />
              </Box>
            </Box>

            
            <Box sx={{
                position: "absolute",
                top: {xs: 20, md: 80, xl: 100},
                right: {xs: 0, md: 64, xl: 112},
                width: {xs: "160px", md: "200px", xl: "270px"},
                height: {xs: "160px", md: "200px", xl: "270px"},
                borderRadius: "50%",
                display: firstImage ? "flex": "none",
                flexDirection: "column",
                alignItems: "center",
                background: "#ffffff78",
                justifyContent: "center",
            }}>
              <CustomText lineHeight={1} fontSize={{xs: "3.5rem", md: "4.25rem", xl: "6.25rem"}} color={"white"} fWeight={"bold"}>150</CustomText>
              <CustomText lineHeight={1} textAlign="center" fontSize={{xs: "1.33rem", md: "1.8rem", xl: "2.5rem"}} color={"white"} fWeight={"bold"}>years of</CustomText>
              <CustomText lineHeight={1} textAlign="center" fontSize={{xs: "1.33rem", md: "1.8rem", xl: "2.5rem"}} color={"white"} fWeight={"bold"}>progress</CustomText>
            </Box>
          </Box>
        </Container>
      </Box>



      


    </Box>
  )
}
