import {
  Button,
  Card,
  CardActions,
  CardMedia,
  FormControl,
  styled,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { ProjectFormData } from "types/interfaces";
import { HDButton } from "components/buttons/HDButton";
import getBase64 from "../utils/getBase64";

const Input = styled("input")({
  display: "none",
});

interface ImageUploaderProps {
  formData: Partial<ProjectFormData>;
  setFormData: Dispatch<SetStateAction<Partial<ProjectFormData>>>;
}

const ImageUploader = ({ formData, setFormData }: ImageUploaderProps) => {
  const { t } = useTranslation();

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      getBase64(file).then((data: string) => {
        setFormData({ ...formData, image: data });
      });
    }
  };

  return (
    <>
      {formData.image ? (
        <Card
          sx={{
            maxWidth: "45ch",
            border: 1,
            borderColor: "grey.400",
            my: 2,
            boxShadow: 0,
          }}
        >
          <CardMedia
            sx={{ height: 400, backgroundSize: "contain" }}
            image={formData.image}
            title={t("submitForm.imagePreview")}
          />
          <CardActions>
            <HDButton
              clickEvent={() => setFormData({ ...formData, image: "" })}
              text={t("submitForm.deleteImage")}
              width={"100%"}
            />
          </CardActions>
        </Card>
      ) : (
        <FormControl
          required
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            width: { xs: "100%", md: "50%" },
          }}
        >
          <label htmlFor="upload-image">
            <Input
              type="file"
              accept="img/png, image/jpeg"
              id="upload-image"
              name="upload-image"
              onChange={handleFileUpload}
            />
            <Button
              sx={{
                my: 1,
                p: 5,
                width: 50,
                height: 50,
                borderRadius: "0 0",
                backgroundColor: "#E6E6DF",
                boxShadow: 3,
              }}
              aria-label="upload picture"
              component="span"
            >
              <Add />
            </Button>
          </label>
        </FormControl>
      )}
    </>
  );
};

export default ImageUploader;
