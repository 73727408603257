import { PinDrop } from "@mui/icons-material";
import { Box, Container, Link, SvgIcon, useMediaQuery } from "@mui/material";
import RichText from "components/RichText";
import H3 from "components/typography/H3";
import InfoTextExa from "components/typography/InfoTextExa";
import { Project } from "types/interfaces";
import { useTranslation } from "react-i18next";
import Description from "components/typography/Description";
import H5 from "components/typography/H5";
import CustomText from "components/typography/CustomText";
import { HDButton } from "components/buttons/HDButton";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { cancelParticipation, getStateString, isRunning, participate, selectProject, setVolunteerModal } from "features/projects/projectsSlice";
import { useEffect, useContext, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { selectParticipant, selectParticipantId, setPermissionMissing } from "features/auth/authSlice";
import image from "assets/img/Heidelberg_Materials_Project_Details.jpg"
import { useNavigate } from "react-router-dom";
import { InfoText } from "components/typography";
import { Volunteer } from "components/modals/Volunteer";
import { ReturnUrlContext } from "components/ReturnUrlContext";

export function ProjectDetails(){
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [project, setProject] = useState<Project | undefined>(useAppSelector(selectProject))
  const participantId = useAppSelector(selectParticipantId) as string;
  const participant = useAppSelector(selectParticipant)
  const navigate = useNavigate();
  const { setReturnUrl } = useContext(ReturnUrlContext);
  
  
  useEffect(() => {
    console.log(i18n.language)
  }, [i18n.language])
  if (!project) return <></>
  const handleVolunteerButton = (type: number, id?: number) => {
    switch(type) {
      case 0:
        if(id) {
          dispatch(participate({projectId: id, language: i18n.language})).then((res: any) => {
            if (res.payload.status === "ok"){
              dispatch(setVolunteerModal(true))
              setProject({...project, attending_participants_ids: [...project.attending_participants_ids, participantId]})
            } 
          })
        }
        break;
      case 1:
        setReturnUrl("/project_details");
        navigate("/login")
        dispatch(setPermissionMissing(true))
        break;
      case 2:
        if(id) {
          dispatch(cancelParticipation(id)).then((res: any) => {
            if (res.payload.status === "ok"){
              setProject({...project, attending_participants_ids: project?.attending_participants_ids.filter((id) => id !== participantId)})
            }
          })
        }
        break;
      default:
        break
    }
  }

  const handleVolunteer = (projectId: number) => {
    dispatch(participate({projectId: projectId, language: i18n.language}));
  }

  const formattedDate = (date: string) => {
    const d = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return d.toLocaleDateString(i18n.language, options);
  }

  return (
    <>
      <Box sx={{
        width: "100%",
        position: "relative",
      }}>
        <Box 
          component={"img"}
          src={image}
          sx={{
            width: "100%",
            height: "100%",
            maxHeight: "50vh",
            borderRadius: {xs: 0, md: "0 0 140px 0"},
            objectFit: "cover"
          }}
        />
      </Box>


      <Container
        maxWidth="xl"
        sx={{
          mt: { xs: 4, sm: 6, md: 8 },
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          overflowY: "clip",
        }}
      >
        <Box>
          <H3>{project.name}</H3>
        </Box>
        <Grid2
          container
          spacing={4}
          sx={{
            mt: { xs: 2, sm: 5, md: 8 },
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
            flexWrap: "wrap",
            "&.css-510fvu-MuiGrid2-root": { flex: "1 1 300px" },
          }}
        >
          <Grid2
            xs={12}
            sm={12}
            md={6}
            lg={4}
            sx={{
              // maxWidth: "400px",
              display: "flex",
              gap: 2,
              flexDirection: "column",
            }}
          >
            <H5 fontSize={{xs: "16pt", md: "26px"}}>{t("Project.title")}</H5>
            <CustomText color={"black"}>
              <RichText
                htmlContent={
                  i18n.language.includes("de")
                    ? project.description
                    : project.description_en
                }
              />
            </CustomText>
          </Grid2>
          <Grid2
            xs={12}
            sm={12}
            md={6}
            lg={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2
            }}
          >
            <Box>
              {
                isRunning(project) ? <>
                
                  <H5 fontSize={{xs: "16pt", md: "26px"}}>{t("Project.volunteers") + (project.volunteers - project.attending_participants_ids.length)}</H5>
                  {project.volunteers > 0 ? (
                    participant ? (
                      !project.attending_participants_ids.includes(
                        participant.id
                      ) ? (
                        <HDButton
                          text={t("Project.volunteer")}
                          clickEvent={() => {
                            handleVolunteerButton(0, project.id);
                          }}
                          // width={{ xs: "140px", md: "168px" }}
                          color="#3B3B3B"
                        />
                      ) : (
                        <Box sx={{display: "flex", flexDirection: "column", gap: 1}}>
                            
                          <InfoText fWeight="bold">
                            {t("Projects.alreadyVolunteering")}
                          </InfoText>
                          <HDButton
                            text={t("Project.deregisteration")}
                            clickEvent={() => {
                              handleVolunteerButton(2, project.id);
                            }}
                            // width={{ xs: "140px", md: "168px" }}
                            color="#3B3B3B"
                          />
                        </Box>
                      )
                    ) : (
                      <HDButton
                        text={t("Project.volunteer")}
                        clickEvent={() => {
                          handleVolunteerButton(1);
                        }}
                        width="auto"
                        color="#3B3B3B"
                      />
                    )
                  ) : null}
                </> : null
              }
            </Box>
            <Box>
              <H5 fontSize={{xs: "16pt", md: "26px"}}>{getStateString(project, i18n.language)}</H5>
              <InfoTextExa>{formattedDate(project.date)}</InfoTextExa>
            </Box>
            <Box>
              <InfoTextExa fWeight={"bold"} color={"#004E2B"}>
                <SvgIcon>
                  <PinDrop />
                </SvgIcon>
                <Link
                  sx={{ ml: 1 }}
                  href={`https://www.google.com/maps/place/${project.latitude},${project.longitude}`}
                  target="_blank"
                >
                  {t("Project.maps")}
                </Link>
              </InfoTextExa>
            </Box>
          </Grid2>
          <Grid2
            xs={12}
            sm={12}
            lg={4}
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: "column",
            }}
          >
            <Box
              component="img"
              src={`${process.env.REACT_APP_ASSET_HOST}${project.image_url}`}
              sx={{ 
                width: "100%",
                // height: { xs: "172px", md: "241px" },
                objectFit: "cover" 
              }}
            />
          </Grid2>
        </Grid2>
      </Container>
      <Volunteer />
    </>
  );
}
