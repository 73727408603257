import { Container, Box, Alert } from "@mui/material";
import { RootState } from "app/store";
import { LoginForm } from "./components";
import { logout } from "features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { HDButton } from "components/buttons/HDButton";
import backgroundImage from "assets/img/Heidelberg_Materials_Header_Login.jpg"
import { Navigate, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

export default function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const permissionsMissing = useAppSelector(
    (state: RootState) => state.auth.permissionsMissing
  );

  
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Box sx={{
      minHeight: "91vh",
      position: "relative",
      display: "flex",
      flexDirection: "column",
    }}>
      <Box sx={{
          position: "relative",
          height: {xs: "200px", md: "300px", xl: "500px"},
      }}>
        <Box
          component="img"
          src={backgroundImage}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}/>
      </Box>
      {permissionsMissing ? (<Alert severity="warning">{t("Login.loginPrompt")}</Alert>) : null}
      <Box
          component="img"
          src="/images/HeidelbergSkyline.svg"
          sx={{
            position: "absolute",
            width: "100%",
            bottom: "0px",
          }}/>


      <Box>
        <Container maxWidth="xl" sx={{ 
          mt: {xs: 2, md: 5}, 
          display: "flex", 
          justifyContent: "center",
          minHeight: "400px",
        }}>
          <LoginForm />
        </Container>
      </Box>

      {/* spacing box */}
      <Box sx={{
        position: "relative",
        width: "100%",
        height: {xs: "100px", md: "280px", xl: "16vw"},
        flexGrow: 2,
      }}/>
    </Box>
  );
}
