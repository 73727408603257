import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children?: ReactNode,
  color?: String;
  fWeight?: String;
  fontSize?: any;
  mx?: any;
  my?: any;
  whiteSpace?: string;
  highlighted?: boolean;
  textAlign?: string;
  // any props that come into the component
}

export default function NavigationText({ children, color = "#004e2b", fWeight="bold", mx, my, whiteSpace="normal", highlighted, textAlign = "start", fontSize={xs: "1.1669rem", md: "1.75rem"} }: Props) {
  return (
    <Typography 
    textAlign="center"
    sx={{
      fontFamily: 'Lexend, sans-serif',
      color: `${color}`,
      fontWeight: `${fWeight}`,
      textDecoration: highlighted ? "underline 1px #00dd39" : "none",
      fontSize: `${fontSize}`,
      textAlign: textAlign,
      whiteSpace: `${whiteSpace}`,
      ":&hover": {
        color: "rgb(0,199,50)"
      }
    }}
    >
      {children}
    </Typography>
  );
}
