import Box from "@mui/material/Box";
import { InfoText, NavigationText } from "../typography/index";
import { Container, Divider, IconButton, SvgIcon, colors } from "@mui/material";
import { HDDivider } from "components/HDDivider";
import image from "assets/img/Heidelberg_Materials_Footer.jpg"
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useTranslation } from "react-i18next";

const boldLinks = [
  { name: "FooterNavbar.homepage", url: "/" },
  { name: "FooterNavbar.newsfeed", url: "newsfeed" },
  { name: "FooterNavbar.projects", url: "projectlist" },
];

const socials = [
  { 
    name: "LinkedIn",
    url: "https://www.linkedin.com/company/heidelbergmaterials/?originalSubdomain=de",
    icon: LinkedInIcon,
  },
  { 
    name: "twitter",
    url: "https://twitter.com/hd_materials?lang=de",
    icon: TwitterIcon,
  },
  {
    name: "instagram",
    url: "https://www.instagram.com/heidelbergmaterials/?hl=de",
    icon: InstagramIcon,
  },
  {
    name: "youtube",
    url: "https://www.youtube.com/c/heidelbergcementdeutschland",
    icon: YouTubeIcon,
  },
];

const links = [
  { name: "FooterNavbar.legalNotice", url: "https://www.heidelbergmaterials.com/de/impressum" },
  { name: "FooterNavbar.privacyPolicy", url: "https://www.heidelbergmaterials.com/de/datenschutz" },
];

export function FooterNavbar() {
  const { t } = useTranslation();

  return (
    <Box sx={{maxHeight: "64vh"}}>
      <Box 
        component="img"
        src={image}
        sx={{
          position: "absolute", 
          width: "100%",
          height: 800,
          objectFit: "cover"
        }}
      />
        <Container sx={{
            pt: 2, 
            pb: 6, 
            maxWidth: "100% !important",
            mx: "unset",
            zIndex: 10,
            position: "absolute",
            backgroundColor: "white",
            borderRadius: "0 0 100px 0",
            width: "100%",
        }}>
          <HDDivider />
          <Container
            maxWidth="xl"
            sx={{
              paddingX: "0px !important"
            }}
          >
            <Box
              sx={{
                padding: 0,
                display: "flex",
                flexWrap: "wrap",
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                gap: {xs: 4, md: 6, lg: 8, xl: 14},
                paddingTop: 4,
              }}
            >
              <Box sx={{flexBasis: "40%"}}>
                <Box
                  component="a"
                  href="/"
                >
                  <Box 
                    component="img"
                    src="/images/HeidelbergMaterials.svg"
                    width={139}
                    height={42}
                  />
                </Box>
              </Box>
                {/* Left aligned menue items */}

              <Box
                  sx={{
                    display: "flex",
                    flexBasis: {xs: "100%", md: "40%"},
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: 4
                  }}
                >
                  <Box sx={{
                      display: "flex",
                      flexDirection: { xs: "row" , md: "column" },
                      justifyContent: "space-between",
                      width: "100%",
                      gap: 5
                    }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        width: "50%",
                      }}>
                      {boldLinks.map((link, index) => (
                          <Box
                            key={link.url}
                            component="a"
                            href={link.url}
                            sx={{textDecoration: "none"}}
                          >
                            <NavigationText color="#294a33" fontSize="1.2rem">
                              {t(link.name)}
                            </NavigationText>
                          </Box>
                        ))
                      }
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        width: "50%",
                    }}>
                      {links.map((link, index) => (
                        <Box
                          key={index}
                          component="a"
                          href={link.url}
                          sx={{textDecoration: "none"}}
                          target={"_blank"}
                        >
                          <NavigationText textAlign="left" color="black" fontSize="1.2rem"  >
                            {t(link.name)}
                          </NavigationText>
                        </Box>
                      ))}
                  </Box>
                </Box>
                
                <Box sx={{display: { xs: "none", md: "flex"}, mt: 6, gap: 5}}>
                {socials.map((social, index) => (
                    <Box
                      key={index}
                      component="a"
                      href={social.url}
                      sx={{
                        textDecoration: "none", mt: 0
                      }}
                    >
                      <InfoText fWeight="bold" mt={0} mx={0} textAlign="left">
                        {social.name}
                      </InfoText>
                    </Box>
                  ))
                }
                </Box>
              
              </Box>

              <Box sx={{
                width: "100%",
                display: { xs: "flex", md: "none", xl: "none" },
                flexDirection: "column",
                alignItems: "center",
              }}>
                <NavigationText textAlign="center" fWeight="bold" color="black" fontSize="1.2rem"  >
                  Follow us on
                </NavigationText>
                <Box sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    width: "260px",
                    mb: 3,
                }}>
                  {socials.map((social, index) => (
                      <Box
                        key={index}
                        component="a"
                        href={social.url}
                        sx={{
                          textDecoration: "none", 
                          mt: 0,
                          color: "black",
                        }}
                      >
                        <SvgIcon sx={{
                          fontSize: "34px",
                        }} component={social.icon} inheritViewBox />

                        {/* <Box 
                          component="img"
                          src={social.icon}
                        /> */}
                        
                      </Box>
                    ))
                  }
                </Box>
              </Box>
            </Box>
          </Container>
        </Container>
      </Box>
  );
 
}
