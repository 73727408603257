import { HeaderNavbar } from "./components/header-navbar/HeaderNavbar";
import { FooterNavbar } from "./components/footer-navbar/FooterNavbar";
import styles from "./App.module.css";
import { Outlet, ScrollRestoration, useNavigate } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { setupNavigator } from "app/axios";
import { Suspense } from "react";


function Main() {
  const navigateTo = useNavigate();
  setupNavigator(navigateTo);

  return (
    <div className={styles.app}>
      <ScrollRestoration />
      <HeaderNavbar loggedIn={true} />
      <div className={styles.outlet}>
        <Outlet />
      </div>
      <FooterNavbar />
    </div>
  );
}

export default function App() {
  return (
    <Suspense fallback="...loading">
      <Main />
    </Suspense>
  );
}
