import { createContext, useState, ReactNode } from "react";

interface ReturnUrlContextType {
  returnUrl: string;
  setReturnUrl: (url: string) => void;
};

const ReturnUrlContext = createContext<ReturnUrlContextType>({
  returnUrl: "/my_section",
  setReturnUrl: () => {},
});

interface ReturnUrlProviderProps {
  children: ReactNode;
};

const ReturnUrlProvider = ({ children }: ReturnUrlProviderProps) => {
  const [returnUrl, setReturnUrl] = useState("");

  return (
    <ReturnUrlContext.Provider value={{ returnUrl, setReturnUrl }}>
      {children}
    </ReturnUrlContext.Provider>
  );
};

export { ReturnUrlContext, ReturnUrlProvider };
