import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children?: ReactNode,
  color?: String,
  fWeight?: String,
  textAlign?: any,
  mx?: any,
  my?: any,
  border?: any,
  fontSize?: any,
  className?: any,
  // any props that come into the component
}

export default function HeaderText({ 
    children,
    color = "#004e2b",
    fWeight = "700", mx, my, border,
    textAlign= "start",
    fontSize={xs: "1.5rem", md: "3rem"},
    className="" 
}: Props) {
  return (
    <Typography
      variant="h2"
      noWrap
      className={className}
      sx={{
        fontFamily: 'Lexend Exa HM',
        // mx: {xs:"auto", md:`${mx}`},
        my: my,
        fontWeight: `${fWeight}`,
        color: `${color}`,
        textDecoration: "none",
        fontSize: fontSize,
        textAlign: `${textAlign}`,
        whiteSpace: "inherit"
      }}
    >
      {children}
    </Typography>
  );
}
