import { useContext } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  styled,
  useMediaQuery,
} from "@mui/material";
import { HDButton } from "components/buttons/HDButton";
import { InfoText, NavigationText } from "components/typography";
import HeaderText from "components/typography/HeaderText";
import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { SlideBox } from "./components/SlideBox";
import MapSection from "./components/MapSection";
import { useTranslation } from "react-i18next";
import infoImage from "assets/img/Heidelberg_Materials_Projekt_Info.jpg";

import backgroundImage1 from "assets/img/Heidelberg_Materials_Slider_1.jpg";
import backgroundImage2 from "assets/img/Heidelberg_Materials_Slider_2.jpg";
import backgroundImage3 from "assets/img/Heidelberg_Materials_Slider_3.jpg";

import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { login, setPermissionMissing } from "features/auth/authSlice";
import { RootState } from "app/store";
import { ReturnUrlContext } from "components/ReturnUrlContext";

import {
  selectProjects,
  selectTreesAndBenches,
} from "features/projects/projectsSlice";

import CountUp from "react-countup";
import { InView } from "react-intersection-observer";
import styles from "./Homepage.module.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const components: React.ReactNode[] = [
  <SlideBox
    backgroundImage={backgroundImage1}
    firstImage={true}
    navigatePath="#info"
    navigateText="Homepage.readMore"
    labelText="Homepage.anniversary"
    infoText1="Homepage.anniversaryInfo1"
    infoText2="Homepage.anniversaryInfo2"
  />,
  <SlideBox
    backgroundImage={backgroundImage2}
    navigatePath="/newsfeed"
    navigateText="Homepage.readMore"
    labelText="Homepage.upToDate"
    infoText1="Homepage.upToDateInfo"
  />,
  <SlideBox
    backgroundImage={backgroundImage3}
    navigatePath="/projectlist"
    navigateText="Homepage.readMore"
    labelText="Homepage.projectList"
    infoText1="Homepage.projectListInfo1"
    infoText2="Homepage.projectListInfo2"
  />,
];

export default function Homepage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const { setReturnUrl } = useContext(ReturnUrlContext);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % components.length);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep - 1 < 0 ? components.length - 1 : prevActiveStep - 1
    );
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const [queryParameters] = useSearchParams();
  const dispatch = useAppDispatch();
  const email = queryParameters.get("email") || "";
  const code = queryParameters.get("code") || "";
  const projects = useAppSelector(selectProjects);
  const isAuthenticated = useAppSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const treesAndBenches = useAppSelector(selectTreesAndBenches);
  const treesAndBenchesMax = 150;
  const treesAndBenchesDone = treesAndBenches?.done || 0;
  const treesAndBenchesOngoing = treesAndBenches?.ongoing || 0;
  const [barVisible, setBarVisible] = useState(false);

  useEffect(() => {
    if (email && code) {
      dispatch(login({ email, code })).then(function (ev) {
        if (ev.type === "auth/login/fulfilled") {
          dispatch(setPermissionMissing(false));
        }
      });
    }
  }, [email, code, dispatch]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          width: { xs: "100%" },
          gap: { xs: 1, md: 3, lg: 4, xxl: 10 },
          position: "relative",
        }}
      >
        <AutoPlaySwipeableViews
          axis="x"
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={60000}
        >
          {components.map((step: React.ReactNode, index) => (
            <Box key={index}>
              {Math.abs(activeStep - index) <= 2 ? step : null}
            </Box>
          ))}
        </AutoPlaySwipeableViews>
        <Box
          component="div"
          sx={{
            maxHeight: "inherit",
            justifyContent: "center",
            position: "absolute",
            left: { xs: "50%", md: "unset" },
            transform: { xs: "translateX(-50%)", md: "unset" },
            top: { md: "20px" },
            right: { md: "50px" },
            background: { md: "transparent" },
            pt: 2,
            display: { md: "flex" },
            gap: 2,
            "& .MuiMobileStepper-dot": {
              width: { xs: 20, md: 40 },
              height: { xs: 10, md: 15 },
              backgroundColor: { xs: "#004e2b", md: "white" },
              opacity: 0.5,
              borderRadius: "0 0 0 0",
            },
            "& .MuiMobileStepper-dotActive": {
              opacity: 1,
            },
          }}
        >
          {components.map((_, index) => (
            <Box
              key={index}
              className={`MuiMobileStepper-dot ${
                activeStep === index ? "MuiMobileStepper-dotActive" : ""
              }`}
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                setActiveStep(index);
              }}
            />
          ))}
        </Box>

        <Container
          maxWidth="xl"
          sx={{
            // paddingLeft: "0px !important",
            display: { xs: "none", md: "block" },
          }}
        >
          <Button
            size="small"
            onClick={handleBack}
            sx={{
              color: "white",
              bottom: 40,
              left: 0,
            }}
          >
            <WestIcon />
          </Button>
          <Button
            size="small"
            disabled={false}
            onClick={handleNext}
            sx={{
              bottom: 40,
              color: "white",
            }}
          >
            <EastIcon />
          </Button>
        </Container>
      </Box>

      <Box
        sx={{
          paddingTop: 2,
          width: "100%",
          height: "16vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <IconButton
          sx={{ alignSelf: "center" }}
          onClick={() => {
            const navHeight = 100;
            const projectCounterView =
              document.getElementById("projectCounterView");
            window.scrollTo({
              top: (projectCounterView?.offsetTop || navHeight) - navHeight,
              behavior: "smooth",
            });
          }}
        >
          <Box
            component="img"
            src="/images/BaumrunterscrollenIcon.svg"
            sx={{
              width: 26,
            }}
          />
        </IconButton>
      </Box>

      <Box
        id="projectCounterView"
        sx={{
          position: "relative",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          gap: 6,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: { xs: "inherit", md: "absolute" },
            left: { xs: "0px", md: "20%", xl: "25%" },
            top: { xs: "0px", md: "20px", xl: "5%" },
            width: { xs: "80%", md: "25%", lg: "15%" },
          }}
        >
          <HeaderText
            textAlign={"center"}
            fontSize={{ xs: "5rem", md: "8rem" }}
          >
            <CountUp end={treesAndBenchesDone} enableScrollSpy scrollSpyOnce />
          </HeaderText>
          <NavigationText>{t("Homepage.projectProgress")}</NavigationText>
        </Box>
        <Box
          sx={{
            position: { xs: "inherit", md: "absolute" },
            right: { xs: "0px", md: "20%", xl: "25%" },
            top: { xs: "0px", md: "20px", xl: "5%" },
            width: { xs: "80%", md: "25%", lg: "15%" },
          }}
        >
          <HeaderText
            textAlign={"center"}
            color="#4b8242"
            fontSize={{ xs: "5rem", md: "8rem" }}
          >
            <CountUp
              end={treesAndBenchesOngoing}
              enableScrollSpy
              scrollSpyOnce
            />
          </HeaderText>
          <NavigationText color="#4b8242">
            {t("Homepage.plannedProgress")}
          </NavigationText>
        </Box>
        <Box sx={{ position: "relative", width: "100%" }}>
          <Box
            component="img"
            src="/images/IllustrationHeidelberg.svg"
            sx={{
              display: { xs: "none", md: "inherit" },
              width: "100%",
            }}
          />
          <Box
            component="img"
            src="/images/HeidelbergSkyline.svg"
            sx={{
              display: { xs: "inherit", md: "none" },
              width: "100%",
            }}
          />
          <Box
            id="loading"
            sx={{
              position: "absolute",
              bottom: 0,
              width: { xs: "80%", md: "65%" },
              left: { xs: "10%", md: "23%" },
              height: { xs: "17%", md: "7%" },
              borderRadius: "0 35px",
            }}
          >
            <Box
              id="loading-box"
              sx={{
                marginLeft: "2px",
                height: "100%",
                borderRadius: "0 2.2vw 0 2.2vw",
                overflow: "hidden",
                display: "flex",
              }}
            >
              <Box
                className={barVisible ? styles.animate : ""}
                sx={{
                  height: "100%",
                  backgroundColor: "#004E2B",
                  maxWidth: `calc(100% * ${
                    treesAndBenchesDone / treesAndBenchesMax
                  })`,
                }}
              ></Box>
              <Box
                className={barVisible ? styles.animate : ""}
                sx={{
                  height: "100%",
                  backgroundColor: "#00843D",
                  maxWidth: `calc(100% * ${
                    treesAndBenchesOngoing / treesAndBenchesMax
                  })`,
                }}
              ></Box>
            </Box>
          </Box>
          <Box
            component="img"
            src="/images/BankLadebalken.svg"
            sx={{
              position: "absolute",
              bottom: 0,
              width: { xs: "80%", md: "65%" },
              left: { xs: "10%", md: "23%" },
            }}
          />
          <Box
            component="img"
            src="/images/BaumIcon.svg"
            sx={{
              display: { xs: "none", md: "inherit" },
              position: "absolute",
              bottom: 0,
              width: { md: "7%" },
              left: { xs: "40px", md: "3%" },
            }}
          />
          <Box
            component="img"
            src="/images/BaumIcon.svg"
            sx={{
              display: { xs: "none", md: "inherit" },
              position: "absolute",
              bottom: 0,
              width: { md: "5%" },
              left: { md: "15%" },
            }}
          />
          <Box
            component="img"
            src="/images/BaumIcon.svg"
            sx={{
              display: { xs: "none", md: "inherit" },
              position: "absolute",
              bottom: 0,
              width: { md: "4%" },
              left: { md: "10%" },
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          backgroundColor: "#004e2b",
          justifyContent: "center",
          paddingTop: 8,
          paddingBottom: { xs: 8, md: 14 },
        }}
      >
        <InView
          as="div"
          delay={200}
          threshold={1}
          triggerOnce
          onChange={(inView: boolean) => setBarVisible(inView)}
        ></InView>
        <Container>
          <HeaderText textAlign={{ xs: "center", md: "start" }} color="white">
            {t("Homepage.projectsAtAGlance")}
          </HeaderText>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: { xs: "none", lg: "block" },
                position: "absolute",
                backgroundColor: "#e6e6df",
                width: "260px",
                right: 0,
                top: 0,
                zIndex: 1000,
                padding: "24px 24px 54px 24px",
                borderRadius: "0px 0px 0px 54px",
              }}
            >
              <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                <Box
                  component="img"
                  src="/images/ProjekteInAbstimmung.svg"
                  sx={{ width: 24, height: 24 }}
                />
                <InfoText mt={0} fWeight={"bold"} color="black">
                  {t("Homepage.inCoordination")}
                </InfoText>
              </Box>
              <Box
                sx={{ display: "flex", gap: 3, alignItems: "center", mt: 2 }}
              >
                <Box
                  component="img"
                  src="/images/LaufendeProjekte.svg"
                  sx={{ width: 24, height: 24 }}
                />
                <InfoText mt={0} fWeight={"bold"} color="black">
                  {t("Homepage.runningProjects")}
                </InfoText>
              </Box>
              <Box
                sx={{ display: "flex", gap: 3, alignItems: "center", mt: 2 }}
              >
                <Box
                  component="img"
                  src="/images/UmgesetzteProjekte.svg"
                  sx={{ width: 24, height: 24 }}
                />
                <InfoText mt={0} fWeight={"bold"} color="black">
                  {t("Homepage.implementedProjects")}
                </InfoText>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                maxWidth: { md: "100%", lg: "80%" },
              }}
            >
              <MapSection projects={projects} />
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "block", lg: "none" },
              paddingTop: 2,
              paddingBottom: 6,
              paddingX: 0,
            }}
          >
            <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
              <Box
                component="img"
                src="/images/LaufendeProjekte.svg"
                sx={{ width: 24, height: 24 }}
              />
              <InfoText mt={0} fWeight={"bold"} color="white">
                {t("Homepage.runningProjects")}
              </InfoText>
            </Box>
            <Box sx={{ display: "flex", gap: 3, alignItems: "center", mt: 2 }}>
              <Box
                component="img"
                src="/images/UmgesetzteProjekte.svg"
                sx={{ width: 24, height: 24 }}
              />
              <InfoText mt={0} fWeight={"bold"} color="white">
                {t("Homepage.implementedProjects")}
              </InfoText>
            </Box>
          </Box>

          <Grid
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 4, md: 12 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <HeaderText fontSize={"1.4rem"} color="white">
                {t("Homepage.toProjectList")}
              </HeaderText>
              <HDButton
                width={"auto"}
                text={t("Homepage.showProgress")}
                clickEvent={() => {
                  navigate("projectlist");
                }}
                color="white"
                backgroundColor="#004e2b"
                whiteSpace={"nowrap"}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <HeaderText fontSize={"1.4rem"} color="white">
                {t("Homepage.suggestAPlace")}
              </HeaderText>
              <HDButton
                width={"auto"}
                text={t("Homepage.submitSuggestion")}
                clickEvent={() => {
                  if (isAuthenticated) {
                    navigate("/create_project");
                  } else {
                    setReturnUrl("/create_project");
                    navigate("/login");
                    dispatch(setPermissionMissing(true));
                  }
                }}
                color="white"
                backgroundColor="#004e2b"
                whiteSpace={"nowrap"}
              />
            </Box>
          </Grid>
        </Container>
      </Box>

      <Box id="homepageInfo">
        <Box sx={{ position: "relative" }}>
          <Box
            component="img"
            sx={{
              width: "100%",
              height: { xs: "240px", md: "500px", xl: "640px" },
              objectFit: "cover",
            }}
            src={infoImage}
          />
          <Container
            sx={{
              bottom: -50,
              width: "100%",
              height: "60px",
              display: "flex",
              flexDirection: "column-reverse",
              direction: "rtl",
            }}
          >
            <Box
              sx={{
                // right: 100,
                height: { xs: 100, md: 140 },
                width: { xs: 140, md: 180 },
                backgroundColor: "#873cff",
                borderRadius: "0 0 0 50px",
              }}
            >
              <Box
                component="img"
                src="/images/Info.svg"
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>
          </Container>
        </Box>

        <Container
          sx={{
            paddingTop: { xs: 2, md: 2 },
            paddingBottom: { xs: 8, md: 18, xl: 24 },
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: "100%", md: "80%", lg: "60%" },
              gap: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <HeaderText>{t("Homepage.projectInfoTitle")}</HeaderText>
            <InfoText>{t("Homepage.projectInfo")}</InfoText>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
