import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  phone: Yup.string().required("validation.required"),
  name: Yup.string().required("validation.required"),
  street: Yup.string().when(
    ["latitude", "longitude"],
    ([latitude, longitude]) => {
      if (latitude && longitude) return Yup.string();
      return Yup.string().required("validation.required");
    }
  ),
  houseNumber: Yup.string().when(
    ["latitude", "longitude"],
    ([latitude, longitude]) => {
      if (latitude && longitude) return Yup.string();
      return Yup.string().required("validation.required");
    }
  ),
  zip: Yup.string().when(["latitude", "longitude"], ([latitude, longitude]) => {
    if (latitude && longitude) return Yup.string();
    return Yup.string()
      .required("validation.required")
      .matches(
        /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/,
        "validation.invalid"
      );
  }),
  city: Yup.string().when(
    ["latitude", "longitude"],
    ([latitude, longitude]) => {
      if (latitude && longitude) return Yup.string();
      return Yup.string().required("validation.required");
    }
  ),
  district: Yup.string(),
  latitude: Yup.number()
    .transform((cv) => (isNaN(cv) ? undefined : cv))
    .typeError("validation.invalid"),
  longitude: Yup.number()
    .transform((cv) => (isNaN(cv) ? undefined : cv))
    .typeError("validation.invalid"),
  status: Yup.string().required("validation.required"),
  image: Yup.string(),
  length: Yup.number()
    .required("validation.required")
    .typeError("validation.invalid")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .positive("validation.invalid"),
  width: Yup.number()
    .required("validation.required")
    .typeError("validation.invalid")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .positive("validation.invalid"),
  distance: Yup.number()
    .typeError("validation.invalid")
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(0, "validation.invalid"),
  surface: Yup.string().required("validation.required"),
  date: Yup.string(),
  datestatus: Yup.string(),
  siteusage: Yup.string().required("validation.required"),
});

export default validationSchema;
