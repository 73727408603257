import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import createTheme from "@mui/material/styles/createTheme";
const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Lexend Exa, sans-serif';
        font-style: normal;
        font-weight: 400;
      }
      `,
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Lexend Exa, sans-serif",
    },
  },
  palette: {
    primary: {
      main: "#004e2b",
    },
    secondary: {
      main: "#e6e6df",
    },
    text: {
      primary: "#232326",
      secondary: "#00000",
    },
  },
  
});

// tabs: {
  // inactive: "#f6f3ee",
  // active: "#ffffff",
// },
export default function HDMatsThemeProvider({
  children,
}: {
  children: any;
}) {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider>{children}</StyledEngineProvider>
    </ThemeProvider>
  );
}
