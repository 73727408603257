import {
  OutlinedInput,
  FormHelperText,
  OutlinedInputProps,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface HDOutlinedInputProps
  extends Omit<
    OutlinedInputProps,
    "name" | "placeholder" | "error"
  > {
  name: string;
  placeholder?: string;
  error?: string;
}

const HDOutlinedInput: React.FC<HDOutlinedInputProps> = ({
  name,
  error,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      <OutlinedInput
        name={name}
        placeholder={t(`submitForm.${name}`)}
        error={!!error}
        {...props}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

export default HDOutlinedInput;
