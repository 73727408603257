interface teaserHtmlParameters {
  date?: string;
  street?: string;
  houseNumber?: string;
  zip?: string;
  city?: string;
  district?: string;
}

const teaserHtml = ({
  date,
  street,
  houseNumber,
  zip,
  city,
  district,
}: teaserHtmlParameters): string => {
  const formattedParts = [];

  if (date) {
    formattedParts.push(date);
  }

  if (street || houseNumber || zip || city || district) {
    formattedParts.push(
      [
        [street, houseNumber].filter((n) => n).join(" "),
        [zip, city, district].filter((n) => n).join(" "),
      ]
        .filter((n) => n)
        .join(", ")
    );
  }

  if (formattedParts.length === 0) {
    return "";
  }

  const listItems = formattedParts.map((part) => `<li>${part}</li>`).join("");
  const html = `<ul>${listItems}</ul>`;

  return html
};

export default teaserHtml;
