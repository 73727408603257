import { Box, Container, Divider, Grid, Link, Pagination } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { HDDivider } from "components/HDDivider";
import { HeaderText, InfoText } from "components/typography";
import { useTranslation } from "react-i18next";
import backgroundImage from "assets/img/muldenkipper_im_steinbruch.jpg";
import DescriptionText from "components/typography/NavigationText";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useNavigate } from "react-router-dom";
import {
  selectProjects,
  isPending,
  isRunning,
  isFinished,
  getRawDateString,
  getStateString,
  setProject,
} from "features/projects/projectsSlice";
import {
  getRawDateStringNews,
  selectNewsfeed,
} from "features/newsfeed/newsfeedSlice";
import { NewsItem, Project } from "types/interfaces";
import H4 from "components/typography/H4";
import { useState } from "react";

const FeedIdx = 0;
const ProjectIdx = 1;

export function Newsfeed() {
  const { t, i18n } = useTranslation();
  const newsfeed = useAppSelector(selectNewsfeed);
  const projects = useAppSelector(selectProjects);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Anzahl der Elemente pro Seite

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function getProjectById(id: number): Project | undefined {
    for (let i = 0; i < projects.length; i++) {
      let project = projects[i];
      if (project.id === id) {
        return project;
      }
    }
    return undefined;
  }

  // collect list
  const newsList: [NewsItem, Project][] = [];
  for (let i = 0; i < newsfeed.length; i++) {
    let feed = newsfeed[i];
    if (feed.value && feed.program_item?.id) {
      let project = getProjectById(feed.program_item.id);
      if (project !== undefined) {
        if (isPending(project) || isRunning(project) || isFinished(project)) {
          newsList.push([feed, project]);
        }
      } else {
        console.warn("could not find project for news", feed);
      }
    } else {
      console.warn("invalid news", feed);
    }
  }
  const handleGoToDetails = (project: any) => {
    dispatch(setProject(project));
    navigate("/project_details");
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNewsList = newsList.slice(indexOfFirstItem, indexOfLastItem);

  const handlePagination = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const generateNews = (item: [NewsItem, Project]) => {
    const missingVolunteers =
      item[ProjectIdx].volunteers -
      item[ProjectIdx].attending_participants_ids.length;

    switch (item[FeedIdx].value) {
      case "In Abstimmung":
      case "In Abstimmung / In alignment":
        return t("Newsfeed.projectInCoordination", {
          name: item[FeedIdx].program_item.name,
        });
      case "Projekt in Planung":
      case "In Planung / In planning":
        if (
          item[ProjectIdx].days_to_date > 14 ||
          item[ProjectIdx].days_to_date <= 0
        ) {
          return t("Newsfeed.projectPlanned", {
            projectName: item[FeedIdx].program_item.name,
          });
        } else if (missingVolunteers > 0) {
          return t("Newsfeed.projectSoonHelpersNeeded", {
            name: item[ProjectIdx].name,
            date: getRawDateString(item[ProjectIdx], i18n.language),
            helpersNeeded: missingVolunteers,
          });
        } else {
          return t("Newsfeed.projectSoonHelpersFull", {
            amountOfDays: getDate2WeeksBefore(item[ProjectIdx].date),
          });
        }
      case "Projekt umgesetzt":
      case "Umgesetzt / Implemented":
        return t("Newsfeed.projectImplemented", {
          name: item[ProjectIdx].name,
          date: getRawDateString(item[ProjectIdx], i18n.language),
        });
    }
  };

  const getDate2WeeksBefore = (projDate: string) => {
    let date = new Date(projDate);
    date.setDate(date.getDate() - 14);
    return date;
  };

  return (
    <Box
      sx={{
        marginBottom: "20px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          minHeight: { xs: "auto", md: "400px" },
          gap: 4,
        }}
      >
        <Box
          component={"img"}
          src={backgroundImage}
          sx={{
            right: 0,
            position: { xs: "relative", md: "absolute" },
            width: { xs: "100%", md: "50%" },
            height: "100%",
            borderRadius: { xs: "none", md: "0 0 0 140px" },
            objectFit: "cover",
          }}
        />
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: { xs: "100%", md: "50%" },
            }}
          >
            <Box
              sx={{
                paddingRight: { xs: 0, md: 6 },
              }}
            >
              <HeaderText textAlign={"start"}>{t("Newsfeed.title")}</HeaderText>
              <InfoText
                textAlign={"start"}
                fontSize={"1.1rem"}
                color="#004e2b"
                fWeight={"bold"}
              >
                {t("Newsfeed.newsfeedInfo", { number: "keine" })}
              </InfoText>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: { xs: 3, md: 8, lg: 10 },
        }}
      >
        {currentNewsList.map((listItem) => (
          <Box key={listItem[FeedIdx].id}>
            <HDDivider />
            <Grid2
              container
              direction="row"
              sx={{
                position: "relative",
                gap: { xs: 2, md: 0 },
                "&:hover": {
                  "& .projectListImage": {
                    transition: "all 0.4s",
                    transform: "scale(1.04)",
                    // transform: "scale(1.02)",
                  },
                  "& .projectListLabel": {
                    color: "#4b8242",
                    textDecoration: "underline 2px #4b8242",
                  },
                },
              }}
              onClick={() => handleGoToDetails(listItem[ProjectIdx])}
            >
              <Grid2 xs={12} md={3} order={{ xs: 1, md: 3 }}>
                <Box
                  className="projectListImage"
                  component="img"
                  src={`${process.env.REACT_APP_ASSET_HOST}${listItem[ProjectIdx].image_url}`}
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    // maxWidth: 300
                  }}
                />
              </Grid2>
              <Grid2 xs={12} md={2} order={{ xs: 3, md: 2 }}>
                <Box sx={{ display: "flex", gap: "2rem" }}>
                  <InfoText>
                    {getRawDateStringNews(listItem[FeedIdx], i18n.language)}
                  </InfoText>
                </Box>
              </Grid2>

              <Grid2 xs={12} md={7} order={{ xs: 2, md: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <H4 fWeight="bold" textAlign={"start"}>
                    {listItem[ProjectIdx].name}
                  </H4>
                  <InfoText>{generateNews(listItem)}</InfoText>
                  <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                      >
                        <Box
                          component="img"
                          src="/images/OrtIcon.svg"
                          sx={{
                            paddingRight: "10px",
                            width: "18px",
                          }}
                        />
                        <Link
                          href={`https://www.google.com/maps/place/${listItem[ProjectIdx].latitude},${listItem[ProjectIdx].longitude}`}
                          target="_blank"
                          onMouseDown={(e) => e.stopPropagation()}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <DescriptionText>{t("Project.maps")}</DescriptionText>
                        </Link>
                      </Box>
                      <DescriptionText>
                        {getStateString(listItem[ProjectIdx], i18n.language)}
                      </DescriptionText>
                    </Box>
                  </Box>
                </Box>
              </Grid2>
            </Grid2>
          </Box>
        ))}
        {newsList.length > itemsPerPage && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 4,
            }}
          >
            <Pagination
              count={Math.ceil(newsList.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePagination}
            />
          </Box>
        )}
      </Container>
    </Box>
  );
}
