import App from "App";
import { store } from "app/store";
import { fetchProjects, participantProjects } from "features/projects/projectsSlice";
import { fetchNewsfeed } from "features/newsfeed/newsfeedSlice";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { UserWithAuth } from "views/authenticateUser/Authenticate";
import { UserWithoutAuth } from "views/authenticateUser/unAuthenticated";
import Homepage from "views/homepage/Homepage";
import Login from "views/login/Login";
import { MySection } from "views/mySection/MySection";
import { Newsfeed } from "views/newsfeed/Newsfeed";
import { ProjectDetails } from "views/projectDetails/ProjectDetails";
import { ProjectForm } from "views/projectForm/ProjectForm";
import { ProjectFormSuccess } from "views/projectForm/ProjectFormSuccess";
import { Projectlist } from "views/projectlist/Projectlist";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Homepage />,
        loader: () => {
          return [store.dispatch(fetchProjects())];
        }
      },
      {
        path: "login",
        element: <UserWithoutAuth />,
      },
      {
        path: "my_section",
        element: <UserWithAuth />,
        loader: () => {
          return [store.dispatch(participantProjects())];
        }
      },
      {
        path: "newsfeed",
        element: <Newsfeed />,
        loader: () => {
          return [
            store.dispatch(fetchProjects()),
            store.dispatch(fetchNewsfeed()),
          ];
        }
      },
      {
        path: "projectlist",
        element: <Projectlist />,
        loader: () => {
          return [store.dispatch(fetchProjects())];
        }
      },
      {
        path: "project_details",
        element: <ProjectDetails/>,
      },
      {
        path: "create_project",
        element: <ProjectForm />,
      },
      {
        path: "submission_successful",
        element: <ProjectFormSuccess />,
      },
    ]
  }
]);


export default function HDMatsBrowerRouterProvider() {
  return <RouterProvider router={router} />;
}
