import { Box, IconButton, Modal, Typography, Link } from "@mui/material";
import { useEffect, useRef, useState } from "react";
// import { map } from "@smartmaps/maps"
// import "@smartmaps/maps/dist/smartmaps-maps.css"
// import projectsReducer from "features/projects/projectsSlice";
import { Project } from "types/interfaces";
import CloseIcon from "@mui/icons-material/Close";
import H5 from "components/typography/H5";

import DescriptionText from "components/typography/NavigationText";
import { HDButton } from "components/buttons/HDButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchProjects } from "features/projects/projectsSlice";
import { store } from "app/store";

import {
  isPending,
  isRunning,
  isFinished,
  getDateString,
  getStateString,
  getDescriptionString,
  getNameString,
  setProject,
} from "features/projects/projectsSlice";

declare global {
  interface Window {
    ym: any;
  }
}

interface Props {
  projects: Project[] | undefined;
}

export default function MapSection({ projects }: Props) {
  const isFirstTime = useRef(true);
  const ym = window.ym;
  let inCoordinationIcon: any;
  let runningIcon: any;
  let finishedIcon: any;
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [projectDialogOpen, setProjectDialogOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState<any>(undefined);

  let currentComponentInfo = [
    <Box>
      {currentProject ? getDateString(currentProject, i18n.language) : ""}
    </Box>,
    <Box>
      {currentProject ? getStateString(currentProject, i18n.language) : ""}
    </Box>,
    // <Box sx={{display: "flex", flexDirection: "row", gap: 1}}>
    //   {currentProject ?  <>
    //     <Box
    //       component="img"
    //       src="/images/OrtIcon.svg"
    //       sx={{paddingRight: "10px"}}
    //     />
    //     <Link
    //         href={`https://www.google.com/maps/place/${currentProject.latitude},${currentProject.longitude}`}
    //         target="_blank"
    //     >
    //         <DescriptionText >
    //           {t("Project.maps")}
    //         </DescriptionText>
    //     </Link></> : <></>
    //   }
    // </Box>
  ];

  useEffect(() => {
    if (!isFirstTime || !ym) return;
    store.dispatch(fetchProjects()).then((res) => {
      const projects = res.payload.data;
      debugger
      const mapContainer = document.getElementById("map");
      if (mapContainer?.children.length !== 0) return;
      isFirstTime.current = false;

      var map;
      // const mapInstance =  map(
      //     "map",
      //     "D8x0L0GXm%2b%2fgZB1ZsWhmPSoHNgfXM3AXEB4bbqR8Txa67oh3TIkny2ZWDwJgC8ixlX%2byMCjbrev%2bP6SEBlj7Rg%3d%3d",
      // )

      ym.ready(function (modules: any) {
        // https://docs.yellowmap.com/smartmaps-javascript/beispiele/kartenfunktionalitaet/marker-einzeichnen/
        inCoordinationIcon = new modules.provider.Icon({
          iconUrl: "/images/ProjekteInAbstimmung.svg",
          iconSize: [42, 49],
        });
        runningIcon = new modules.provider.Icon({
          iconUrl: "/images/LaufendeProjekte.svg",
          // iconRetinaUrl: '/images/LaufendeProjekte.svg',
          iconSize: [42, 49],
          // iconAnchor: [15, 15],
          // popupAnchor: [0, 0]
        });
        finishedIcon = new modules.provider.Icon({
          iconUrl: "/images/UmgesetzteProjekte.svg",
          // iconRetinaUrl: '/public/images/UmgesetzteProjekte.svg',
          iconSize: [42, 49],
          // iconAnchor: [15, 49],
          // popupAnchor: [0, 0]
        });

        const iconForProject = (project: Project): any => {
          if (isPending(project)) return inCoordinationIcon
          if (isRunning(project)) return runningIcon
          return finishedIcon
        };

        const center = [49.39875, 8.672434];

        map = ym.map("map", {
          center: center,
          zoom: 12,
        });

        // layerGroup = ym.layerGroup();
        // layerGroup.addTo(map);
        // layerGroup.clear()

        for (let i = 0; i < projects.length; i++) {
          let project = projects[i];

          if (!(isPending(project) || isRunning(project) || isFinished(project))) {
            continue;
          }

          if (!project.latitude || !project.longitude) {
            console.warn("Invalid project:", project);
            continue;
          }

          let position = [Number(project.latitude), Number(project.longitude)];
          var marker = ym.marker(position, {
            clickable: true,
            icon: iconForProject(project),
            title: getNameString(project, i18n.language), // + " (" + getRawDateString(project, i18n.language) + ")",
            ...project,
            index: i,
          });
          marker.addTo(map);
          marker.on("click", function (e: any) {
            setCurrentProject(e.target.options);
            setProjectDialogOpen(true);
          });
        }
      });

      // click on map
      // map.on('click', function(e: any) {
      //   console.log("event", e)
      //   debugger
      // });
    });
  }, []);

  const handleGoToDetails = (index: number) => {
    if (!projects) {
      console.warn("invalid input");
      return;
    }
    dispatch(setProject(projects[index]));
    navigate("/project_details");
  };

  return (
    <Box
      sx={{
        height: "500px",
        paddingY: 2,
        marginLeft: { xs: "-16px", sm: 0 },
        marginRight: { xs: "-16px", sm: 0 },
      }}
    >
      <div id="map" style={{ height: "100%", width: "100%" }}></div>

      <Modal
        open={projectDialogOpen}
        onClose={() => setProjectDialogOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: { xs: "100%", md: "660px" },
            height: { xs: "100%", md: "340px" },
            maxWidth: "100vw",
            backgroundColor: "white",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            display: "flex",
            zIndex: 10000005,
            marginTop: { xs: 12, md: 0 },
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: "8px",
              top: "8px",
              zIndex: 1,
              color: "white",
              backgroundColor: "#00000055",
            }}
            onClick={() => setProjectDialogOpen(false)}
          >
            <CloseIcon
              sx={{
                width: 26,
              }}
            />
          </IconButton>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              component="img"
              src={
                currentProject
                  ? `${process.env.REACT_APP_ASSET_HOST}${currentProject.image_url}`
                  : ""
              }
              sx={{
                width: { xs: "100%", md: "40%" },
                height: { xs: "40%", md: "100%" },
                aspectRatio: "16 / 9",
                objectFit: "cover",
              }}
            />
            <Box
              sx={{
                padding: "10px",
                width: { xs: "unset", md: "60%" },
              }}
            >
              <H5>
                {currentProject
                  ? getNameString(currentProject, i18n.language)
                  : ""}
              </H5>
              {currentProject ? (
                <Box
                  sx={{
                    paddingTop: "8px",
                    paddingBottom: "12px",
                    fontFamily: "Lexend, sans-serif",
                    color: "#4b8242",
                    fontSize: "1rem",
                    ":&hover": {
                      color: "rgb(0,199,50)",
                    },
                  }}
                >
                  <div
                    // style={{
                    //   lineHeight: "1.2 !important",
                    //   maxHeight: "130px",
                    //   overflow: "hidden",
                    // }}
                    dangerouslySetInnerHTML={{
                      __html: getDescriptionString(
                        currentProject,
                        i18n.language
                      ),
                    }}
                  ></div>
                </Box>
              ) : (
                <></>
              )}

              {currentComponentInfo.map((inner, index) => (
                <Box
                  key={index}
                  textAlign="center"
                  sx={{
                    marginBottom: "6px",
                    fontFamily: "Lexend, sans-serif",
                    color: "#004e2b",
                    fontWeight: "bold",
                    textDecoration: "none",
                    fontSize: "1rem",
                    textAlign: "start",
                    whiteSpace: "normal",
                    ":&hover": {
                      color: "rgb(0,199,50)",
                    },
                  }}
                >
                  {inner}
                </Box>
              ))}
              <HDButton
                width={"auto"}
                text={
                  currentProject
                    ? (isRunning(currentProject)
                      ? t("Homepage.registerForSupport") : t("Homepage.seeProject") 
                    ) : ""
                }
                clickEvent={() => handleGoToDetails(currentProject.index)}
                color="black"
                backgroundColor="white"
                hoverBackgroundColor="white"
                hoverBorderColor="black"
                hoverColor="#004e2b"
                whiteSpace={"nowrap"}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
