const getBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        const encoded = reader.result.toString();
        resolve(encoded);
      }
    };
    reader.onerror = (error) => reject(error);
  });

export default getBase64;
