import { Button } from "@mui/material";
import { InfoText } from "components/typography";

interface Props {
  clickEvent: () => void;
  text: any;
  backgroundColor?: string;
  color?: string;
  hoverBorderColor?: string;
  hoverBackgroundColor?: string;
  hoverColor?: string;
  my?: number;
  loading?: boolean;
  width?: any;
  whiteSpace?: any;
  paddingLeft?: any;
  paddingRight?: any;
  marginTop?: any;
  type?: any;
}

export function HDButton(props: Props) {
  const {
    clickEvent,
    text,
    backgroundColor = "white",
    color = "black",
    hoverBorderColor = "#00dd39",
    hoverBackgroundColor = "#00dd39",
    hoverColor = "black",
    my = 2,
    loading = false,
    width = "50%",
    whiteSpace = "normal",
    paddingLeft = 1,
    paddingRight = 4,
    marginTop = undefined,
    type = undefined
  } = props;

  return (
    <>
      <Button
        type={type}
        variant="contained"
        onClick={() => {
          clickEvent();
        }}
        disabled={loading}
        sx={{
          fontFamily: "Lexend Exa HM",
          fontSize: "15px",
          fontWeight: "bold",
          color: color,
          width: width,
          my: my,
          backgroundColor: backgroundColor,
          borderColor: color,
          boxShadow: "none",
          borderRadius: "0 0 20px 0",
          border: "solid 1px",
          paddingLeft: paddingLeft,
          paddingRight: paddingRight,
          justifyContent: "start",
          textTransform: "none",
          whiteSpace: whiteSpace,
          marginTop: marginTop,
          "&:hover": {
            color: hoverColor,
            borderColor: hoverBorderColor,
            backgroundColor: hoverBackgroundColor,
          },
        }}
      >
        {text}
      </Button>
    </>
  );
}
