
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { setPermissionMissing } from "features/auth/authSlice";
import React from "react";
import { Navigate } from "react-router-dom";
import Login from "views/login/Login";


const withAuth = (Component: React.FC ) => {
  return () => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector(
      (state: RootState) => state.auth.isAuthenticated
    );
    
    const permissionsCorrect = () => {
      dispatch(setPermissionMissing(false))
      return <Component />;
    }
    if (!isAuthenticated) {
      return permissionsCorrect();
    } else {
      return <Navigate to="/my_section" />
    }
  };
};



export const UserWithoutAuth = withAuth(Login);
