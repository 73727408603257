import { Box } from "@mui/material";
import { HDButton } from "components/buttons/HDButton";
import { HeaderText, InfoText } from "components/typography";
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";

export function ProjectFormSuccess(){
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          marginTop: { xs: "10px", md: "60px" },
          width: { xs: "95%", md: "fit-content" },
          height: { xs: "480px", md: "480px" },
          backgroundColor: "#004e2b",
          borderRadius: "0 0 0 100px",
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            padding: {
              xs: "20px 20px 20px 20px",
              md: "40px 300px 20px 120px",
            },
            display: "flex",
            flexDirection: "column",
            gap: 2,
            justifyContent: { md: "space-evenly" },
          }}
        >
          <HeaderText color={"white"}>
            {t("submissionSuccesful.title")}
          </HeaderText>
          <Box>
            <InfoText fWeight="bold" color={"white"}>
              {t("submissionSuccesful.success")}
            </InfoText>
          </Box>
          <Box>
            <InfoText color={"white"}>
              {t("submissionSuccesful.subtitle1")}
            </InfoText>
            <InfoText color={"white"}>
              {t("submissionSuccesful.subtitle2")}
            </InfoText>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: { xs: "100%", md: "80%" },
              minWidth: "550px",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
            }}
          >
            <HDButton
              clickEvent={() => {
                navigate("/create_project");
              }}
              text={t("submissionSuccesful.yes")}
              color="white"
              backgroundColor="#004e2b"
            />
            <HDButton
              clickEvent={() => {
                navigate("/");
              }}
              text={t("submissionSuccesful.no")}
              color="white"
              backgroundColor="#004e2b"
            />
          </Box>
        </Box>
      </Box>
      <Box
        component="img"
        src="/images/IllustrationHeidelberg.svg"
        sx={{
          display: "inherit",
          width: "100%",
          position: "absolute",
          bottom: 0,
        }}
      />
      {/* <Box 
        component="img"
        src="/images/HeidelbergSkyline.svg"
        sx={{
          display: {xs: "inherit", md: "none"},
          width: "100%",
        }}
      /> */}
    </Box>
  );
}
