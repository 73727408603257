

export default function limitTextInHTML(node: any, trace: {collectedTextLength: number, textLimitLength: number}) {
  if(node.nodeType == Node.TEXT_NODE) {
    if (trace.collectedTextLength > trace.textLimitLength) {
      node.textContent = "";
    } else {
      let textContent = node.textContent;
      let textContentLen = textContent.length;
      
      if (trace.collectedTextLength + textContentLen > trace.textLimitLength) {
        let len = trace.textLimitLength - trace.collectedTextLength
        node.textContent = textContent.slice(0, len) + " ..."
      } 
      trace.collectedTextLength = trace.collectedTextLength + textContentLen;
    }
  }

  node = node.firstChild
  while (node) {
    limitTextInHTML(node, trace);
    node = node.nextSibling;
  }
}
