import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children?: ReactNode,
  color?: String;
  fWeight?: String;
  fontSize?: any;
  mx?: any;
  my?: any;
  whiteSpace?: string;
  highlighted?: boolean;
  textAlign?: string;
  lineHeight?: any;
  // any props that come into the component
}

export default function CustomText({ children, color = "#004e2b", fWeight="regular", mx, my, whiteSpace="normal", highlighted, textAlign = "start", fontSize={xs: "12pt", md: "20px"}, lineHeight=1.5 }: Props) {
  
  return (
    <Typography 
    textAlign="center"
    sx={{
      fontFamily: 'Lexend HM',
      color: `${color}`,
      fontWeight: `${fWeight}`,
      textDecoration: highlighted ? "underline 1px #00dd39" : "none",
      fontSize: fontSize,
      textAlign: textAlign,
      whiteSpace: whiteSpace,
      lineHeight: lineHeight,
      ":&hover": {
        color: "rgb(0,199,50)"
      }
    }}
    >
      {children}
    </Typography>
  );
}
