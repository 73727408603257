import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import HDMatsBrowerRouterProvider from "provider/HDMatsBrowerRouterProvider";
import { persistore, store } from "./app/store";
import HDMatsThemeProvider from "provider/HDMatsThemeProvider";
import { ReturnUrlProvider } from "components/ReturnUrlContext";

i18n.use(LanguageDetector).init({
  returnNull: false,
  interpolation: { escapeValue: false },
  fallbackLng: "de",
  resources: {
    en: {
      translation: require("./translations/en.json"),
    },
    de: {
      translation: require("./translations/de.json"),
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistore}>
        <HDMatsThemeProvider>
          <ReturnUrlProvider>
            <HDMatsBrowerRouterProvider />
          </ReturnUrlProvider>
        </HDMatsThemeProvider>
      </PersistGate>
    </Provider>
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
