import { Select, SelectProps, MenuItem } from "@mui/material";

const ItemSelect = ({
  name,
  label,
  id,
  labelId,
  defaultValue,
  onChange,
}: SelectProps) => {
  return (
    <>
      <Select
        name={name}
        label={label}
        id={id}
        labelId={labelId}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        <MenuItem value={0}>0</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={6}>6</MenuItem>
        <MenuItem value={7}>7</MenuItem>
      </Select>
    </>
  );
};
export default ItemSelect;
