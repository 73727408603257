import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children?: ReactNode,
  color?: String,
  fWeight?: String,
  textAlign?: any,
  mx?: any,
  my?: any,
  border?: any,
  // any props that come into the component
}

export default function H3({ children, color = "#004B31", fWeight = "700", mx, my, border, textAlign= "start" }: Props) {
  return (
    <Typography
      variant="h3"
      noWrap
      sx={{
        fontFamily: 'Lexend Exa HM',
        // mx: {xs:"auto", md:`${mx}`},
        my:my,
        fontWeight: "bold",
        color: `${color}`,
        textDecoration: "none",
        fontSize: {xs: "20pt", md: "34pt"},
        textAlign: textAlign,
        whiteSpace: "inherit"
      }}
    >
      {children}
    </Typography>
  );
}
