import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "app/axios";
import { RootState } from "app/store";
import { Project, SubmittedProjects, TreesAndBenches } from "types/interfaces";
import limitTextInHTML from "./domTextLimiter";
import { resourceLimits } from "worker_threads";

interface initialStateProps {
  projects?: Project[];
  project?: Project;
  participantProjects?: SubmittedProjects[];
  volunteerModal: boolean;
  treesAndBenches: TreesAndBenches;
}
export const emptyProject = {
  id: 0,
  type: "",
  name: "",
  name_en: "",
  description: "",
  description_en: "",
  teaser: "",
  teaser_en: "",
  image_url: "",
  attending_participants_ids: [],
  volunteers: 0,
  date: "",
  latitude: "",
  longitude: "",
  status: 1,
  days_to_date: 0,
  created_at: "",
  human_status: ""
}

const initialState: initialStateProps = {
  projects: [],
  project: emptyProject,
  participantProjects: undefined,
  volunteerModal: false,
  treesAndBenches: { ongoing: 0, done: 0 },
};



export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;   
    const response = await axiosInstance.get("projects");
    if (response.status === 200) {
      const { data } = response;
      return data
    } else {
      return rejectWithValue(response.data.status);
    }
    
  }
);

export const participantProjects = createAsyncThunk(
  "projects/participant",
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const response = await axiosInstance.get(`profile/projects`, {
      headers: {
        "If-Match": token
      }
    });
    if (response.status === 200) {
      const {  data } = response.data;
      return { data };
    } else {
      return rejectWithValue(response.data.status);
    }
  }
);

export const participate = createAsyncThunk(
  "projects/participate",
  async (props: any, { rejectWithValue }) => {
    const { projectId, language } = props;
    const token = localStorage.getItem("token");
    const response = await axiosInstance.post(`projects/${projectId}/participate`, {
      language
    }, {
      headers: {
        "If-Match": token
      }
    });
    if (response.status === 200) {
      const {  status } = response.data;
      return { status };
    } else {
      return rejectWithValue(response.data.status);
    }
  }
);

export const cancelParticipation = createAsyncThunk(
  "projects/cancelParticipation",
  async (projectId: number, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const response = await axiosInstance.post(`projects/${projectId}/cancel_participation`, {}, {
      headers: {
        "If-Match": token
      }
    });
    if (response.status === 200) {
      const {  status } = response.data;
      return { status };
    } else {
      return rejectWithValue(response.data.status);
    }
  }
);

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<Project>) => {
      state.project = action.payload;
    },
    setVolunteerModal: (state, action: PayloadAction<boolean>) => {
      state.volunteerModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchProjects.fulfilled,
        (
          state,
          action: PayloadAction<{ data: any, meta: any }>
        ) => {
         state.projects = action.payload.data || [];
         state.treesAndBenches = action.payload.meta.trees_and_benches;
        }
      )
      .addCase(
        participantProjects.fulfilled,
        (
          state,
          action: PayloadAction<{ data: any }>
        ) => {
         state.participantProjects = action.payload.data
        }
      )
  },
});





export const { setProject, setVolunteerModal } = projectsSlice.actions;
export const selectParticipantProjects = (state: RootState) => state.projects.participantProjects || undefined;
export const selectProjects = (state: RootState) => state.projects.projects || [];
export const selectProject = (state: RootState) => state.projects.project || undefined;
export const selectVolunteerModal = (state: RootState) => state.projects.volunteerModal;
export const selectTreesAndBenches = (state: RootState) => state.projects.treesAndBenches;
export default projectsSlice.reducer;


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Project getter ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


export function isPending(project: Project): boolean {
  const ProjectStatusCreating = 1;
  return project.status === ProjectStatusCreating;
}

export function isRunning(project: Project): boolean {
  const ProjectStatusRunning = 2;
  return project.status === ProjectStatusRunning && project.days_to_date >= 0;
}

export function isFinished(project: Project): boolean {
  const ProjectStatusFinished = 3;
  return project.status === ProjectStatusFinished || project.days_to_date < 0;
}

export function getRawDateString(project: Project, language: string): string {
  if(language.includes("de")) {
    return new Date(project.date).toLocaleDateString("de", {year: "numeric", day: "numeric",  month: "short"});
  } else {
    return new Date(project.date).toLocaleDateString("en", {year: "numeric", day: "numeric",  month: "short"}); // "en-GB"
  }
}

export function getDateString(project: Project, language: string): string {
  if(language.includes("de")) {
    return "Datum: " + getRawDateString(project, language);
  } else {
    return "Date: " + getRawDateString(project, language); // "en-GB"
  }
}

export function getStateString(project: Project, language: string): string {
  if(project.date && project.status) {
  } else {
    if(language.includes("de")) {
      return "Datum folgt";
    } else {
      return "Date follows";
    }
  }

  if (project.days_to_date < 0) {
    if(language.includes("de")) {
      return "Fertiggestellt am " + getRawDateString(project, "de");
    } else {
      return "Finished on " + getRawDateString(project, "en");
    }
  } else if (project.days_to_date > 1) {
    if(language.includes("de")) {
      return "Status: " + project.days_to_date + " Tage bis Umsetzung!";
    } else {
      return "Status: " + project.days_to_date + " days until implementation!";
    }
  } else if(project.days_to_date == 1) {
    if(language.includes("de")) {
      return "Status: Morgen wird gepflanzt!";
    } else {
      return "Status: Tomorrow will be planted!";
    }
  } else if(project.days_to_date == 0) {
    if(language.includes("de")) {
      return "Status: Heute wird gepflanzt!";
    } else {
      return "Status: Today is planted!";
    }
  } else {
    if(language.includes("de")) {
      return "Status: Pflanze wächst.";
    } else {
      return "Status: Plant grows.";
    }
  }
}

export function getLocationString(project: Project, language: string): string {
  if (!project.latitude || !project.longitude) {
    if(language.includes("de")) {
      return "Unbekannter Ort";
    } else {
      return "Unknown place";
    }
  }
  return project.latitude + ", " + project.longitude;
}

export function getDescriptionString(project: Project, language: string): string {
  let desc;
  if(language.includes("de")) {
    desc = project.description || "";
  } else {
    desc = project.description_en || "";
  }

  let div = document.createElement("div");
  div.innerHTML = desc
  limitTextInHTML(div, {collectedTextLength: 0, textLimitLength: 256});

  return div.outerHTML;
}

export function getNameString(project: Project, language: string): string {
  if(language.includes("de")) {
    return project.name || "";
  } else {
    return project.name_en || "";
  }
}

export function getTeaserString(project: Project, language: string): string {
  if(language.includes("de")) {
    return project.teaser || "";
  } else {
    return project.teaser_en || "";
  }
}
