import { ReactNode } from "react";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface Props {
  children?: ReactNode;
  color?: String;
  fWeight?: String;
  display?: String;
  textAlign?: String;
  fontSize?: any;
  float?: String;
  mdml?: any;
  xsml?: any;
  mt?: any;
  whiteSpace?: any;
  mx?: any;
  padding?: any;
  // any props that come into the component
}

export default function InfoText({
  children,
  color = "#000000",
  fWeight = "regular",
  display,
  float,
  mdml,
  xsml,
  mt = {xs: 1},
  padding,
  mx,
  textAlign = "start",
  fontSize = {xs: "10pt", md: "16px"}
}: Props) {
  return (
    <Typography
      component="p"
      noWrap
      sx={{
        fontFamily: "Lexend HM",
        color: `${color}`,
        textDecoration: "none",
        fontSize: fontSize,
        mt: mt,
        mx: mx,
        whiteSpace: "initial",
        textAlign: `${textAlign}`,
        fontWeight: `${fWeight}`
      }}
    >
      {children}
    </Typography>
  );
}
