import { Divider } from "@mui/material";

interface Props {
  my?: any;
}
export function HDDivider({my=2}: Props) {
  return(
    <Divider sx={{
      width: "100%",
      borderColor: "primary.main",
      my: my,
      height: "1px",
      overflow: "visible"
    }}/>
  )
}