import axios, { AxiosError, AxiosResponse } from "axios";
import { store } from "./store";

const MAX_RETRIES = 0;
const STATUS_UNAUTHORIZED = 401;
const STATUS_GONE = 410;

var navigateTo: Function;

export function setupNavigator(navigate: Function) {
  navigateTo = navigate;
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

function onAxiosSuccess(response: AxiosResponse) {
  return response;
}

function onAxiosError(error: AxiosError) {
  // console.log("axios error: ", error)
  const { config, response } = error;
  if (
    config &&
    response &&
    response.status === STATUS_UNAUTHORIZED &&
    (config.headers?.retry || 0) < MAX_RETRIES
  ) {
    config.headers = config.headers || { retry: 1 };
    switch (typeof config.headers.retry) {
      case "string":
        config.headers.retry = parseInt(config.headers.retry) + 1;
        break;
      case "number":
        config.headers.retry = config.headers.retry + 1;
        break;
      default:
        config.headers.retry = 1;
        break;
    }
    return new Promise((resolve) => {
      resolve(axiosInstance(config));
    });
  }

  if (response && response.status === STATUS_GONE) {
    // clear session and redirect to login and show gone message
    navigateTo("/login");
    // window.history.pushState({}, '', "/login");
  }
  // console.log(error)
  return error.response;
}

axiosInstance.interceptors.response.use(onAxiosSuccess, onAxiosError);

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();

  config.headers = config.headers || {};
  config.headers.authorization = `Token ${process.env.REACT_APP_APP_TOKEN}`;
  config.headers.ifMatch = state.auth.token;
  // console.log("axios config: ", config)

  return config;
});

export default axiosInstance;
