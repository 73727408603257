
import { useAppDispatch, useAppSelector } from "app/hooks";
import { RootState } from "app/store";
import { setPermissionMissing } from "features/auth/authSlice";
import React from "react";
import { Navigate } from "react-router-dom";
import Homepage from "views/homepage/Homepage";
import { MySection } from "views/mySection/MySection";


const withAuth = (Component: React.FC ) => {
  return () => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector(
      (state: RootState) => state.auth.isAuthenticated
    );
    const permissionsMissing = () => {
      dispatch(setPermissionMissing(true))
      return <Navigate to="/login" />;
    }
    
    const permissionsCorrect = () => {
      dispatch(setPermissionMissing(false))
      return <Component />;
    }
    if (isAuthenticated) {
      return permissionsCorrect();
    } else {
      return permissionsMissing();
    }
  };
};



export const UserWithAuth = withAuth(MySection);
