import { ReactNode } from "react";
import { Typography } from "@mui/material";

interface Props {
  children?: ReactNode,
  color?: String,
  fWeight?: String,
  textAlign?: any,
  mx?: any,
  my?: any,
  border?: any,
  fontSize?: any,
  // any props that come into the component
}

export default function H1({ children, color = "white", fWeight = "700", mx, my, border, textAlign= "start", fontSize={xs: "30pt", md: "70px"} }: Props) {
  return (
    <Typography
      variant="h1"
      noWrap
      sx={{
        fontFamily: 'Lexend Exa HM',
        // mx: {xs:"auto", md:`${mx}`},
        my:my,
        fontWeight: `${fWeight}`,
        color: `${color}`,
        textDecoration: "none",
        fontSize: fontSize,
        textAlign: textAlign,
        whiteSpace: "inherit",
        lineHeight: 1.2,
      }}
    >
      {children}
    </Typography>
  );
}
